import { Icon } from "@iconify/react";
import React from "react";

export default function Team({ team }) {
	return (
		<div className="relative w-full h-auto group/team">
			<div className="w-full h-auto rounded border hover:border-gray-300 overflow-hidden bg-white hover:bg-indigo-50/20 duration-300">
				<a
					href={"/our-team/" + team.id}
					className="relative w-full aspect-[1/1] flex items-center"
				>
					<div className="w-full h-full border-b overflow-hidden">
						<img
							src={team.image ?? "/static/images/placeholder.png"}
							alt={team.firstname ?? "property"}
							className="w-full h-full object-cover transition-all group-hover/team:scale-105 duration-300"
						/>
					</div>
				</a>

				{/* Content */}
				<div className="p-3 grid gap-5 justify-items-start">
					<div className="flex flex-col items-start -space-y-1">
						<a href={"/our-team/" + team.id}>
							<h1 className="text-lg font-semibold tracking-tight">
								{(team.firstname ?? "") +
									" " +
									(team.middlename ?? "") +
									" " +
									(team.lastname ?? "")}
							</h1>
						</a>

						<h4 className="text-base text-gray-600">{team.position ?? ""}</h4>
					</div>

					<div className="flex flex-col items-start font-medium text-gray-800">
						<h4 className="text-base flex items-center gap-2">
							<Icon icon={"solar:phone-bold"} />
							<span>{team.phone ?? ""}</span>
						</h4>
					</div>

					<a
						href={"/our-team/" + team.id}
						className="w-full text-sm rounded-sm flex items-center font-light justify-center px-4 h-10 bg-orange-500 hover:bg-orange-600 text-white"
					>
						<span>View profile</span>
					</a>
				</div>
			</div>
		</div>
	);
}
