import React, { Fragment, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import moment from "moment";
import { Icon } from "@iconify/react";
import { doc, getDoc } from "firebase/firestore";
import db from "../../firebase-config";

export default function ViewNewsDetails() {
	const { id } = useParams();
	const [news, setNews] = useState(null);

	const navigate = useNavigate();

	const getNews = async () => {
		const ref = doc(db, "news", id);
		try {
			const docSnap = await getDoc(ref);
			if (docSnap.exists()) {
				setNews(docSnap.data());
			} else {
				setNews({});
			}
		} catch (error) {
			setNews({});
		}
	};

	useEffect(() => {
		getNews();
	}, [id]);

	return (
		<motion.div
			initial={{ opacity: 0, y: 6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
		>
			<div className="w-full px-2 duration-300">
				<div className="w-full md:w-11/12 xl:w-10/12 mx-auto py-8 space-y-8 duration-300">
					<div className="flex items-center justify-between gap-10">
						<div className="flex items-center gap-4">
							<div
								role="link"
								onClick={() => navigate(-1)}
								className="hover:scale-110 duration-200 text-xl cursor-pointer"
							>
								<Icon icon="solar:arrow-left-line-duotone" />
							</div>

							<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-3xl duration-300">
								News Details
							</h1>
						</div>
					</div>
					{news ? (
						Object.keys(news).length > 0 ? (
							<Fragment>
								<div className="w-full h-[45vh] border-b bg-gray-50 overflow-hidden duration-300">
									<img
										src={news.image.url ?? "/static/images/placeholder.jpg"}
										alt={news.title ?? ""}
										className="w-full h-full object-cover duration-300"
									/>
								</div>

								<div className="sticky top-0 bg-white py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 duration-300">
									<div className="space-y-2">
										<div className="w-full font-semibold text-xl sm:text-2xl md:text-3xl tracking-tighter duration-300">
											{news?.title ?? "Unknown"}
										</div>

										<div className="text-base text-gray-600 font-medium">
											{moment(news?.createdAt.seconds * 1000).format(
												"MMM DD, hh:mm A"
											)}
										</div>
									</div>
								</div>

								<div className="px-6 sm:px-10 md:px-14 lg:px-20 pb-6 duration-300">
									<div
										className="text-pretty leading-loose text-left font-medium text-sm"
										dangerouslySetInnerHTML={{ __html: news?.content ?? "" }}
									></div>
								</div>
							</Fragment>
						) : (
							<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
								<div className="grid text-center justify-items-center gap-4">
									<div className="text-6xl text-orange-500">
										<Icon icon="solar:notebook-bold-duotone" />
									</div>

									<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
										News not found.
									</h1>
								</div>
							</div>
						)
					) : (
						<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
							<div className="grid text-center justify-items-center gap-4">
								<div className="text-6xl text-indigo-500 animate-spin">
									<Icon icon="solar:black-hole-line-duotone" />
								</div>

								<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
									Loading news details...
								</h1>
							</div>
						</div>
					)}{" "}
				</div>
			</div>
		</motion.div>
	);
}
