import { doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import db from "../../../../firebase-config";

export default function FinancialCalculator() {
	const [links, setLinks] = useState({});
	const getLinks = async () => {
		try {
			const docRef = doc(db, "contents", "links");
			const docSnap = await getDoc(docRef);
			if (docSnap.exists()) {
				setLinks({ ...docSnap.data() });
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getLinks();
	}, []);

	return (
		<div
			className="w-full text-white bg-cover bg-center"
			style={{
				backgroundImage: "url('/images/1580356536748_1550465012786Jerome-St051-scaled.webp')",
			}}
		>
			<div className="w-full bg-slate-900/80 p-6 xs:p-12 lxs:p-16 md:p-24 flex items-center justify-center duration-300">
				<div className="max-w-2xl grid justify-items-center text-center gap-8 duration-300">
					<h1 className="text-2xl xsm:text-3xl md:text-4xl font-bold tracking-wide uppercase duration-300">
						FINANCIAL CALCULATORS
					</h1>

					<div className="text-sm sm:text-base tracking-wide !leading-relaxed font-light text-gray-100">
						You may estimate your prospective mortgage payments using our easy-to-use financial calculator,
						which will help you make well-informed selections while looking at properties. Enter your
						information to determine your budget right away. With this useful tool, you can eliminate
						uncertainty from your real estate journey.
					</div>

					<div className="flex items-center gap-10 *:rounded-lg *:h-10 *:px-4 *:flex *:items-center *:justify-center *:text-xs lxs:*:text-sm">
						<a
							href={links?.finance ?? ""}
							rel="noreferrer"
							target="_blank"
							className="bg-white text-slate-900 font-semibold hover:text-indigo-800 duration-300"
						>
							<span>Finance Calculator</span>
						</a>

						<a
							href={links?.mortage ?? ""}
							rel="noreferrer"
							target="_blank"
							className="bg-orange-500 hover:bg-orange-600 duration-300"
						>
							<span>Mortage Calculator</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
