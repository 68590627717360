import { useContext, createContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getCookie, getLocalStorage } from "../libs";

const AuthContext = createContext();

const DASHBOARD_PREFIX = "/admin-dashboard";

const AuthProvider = ({ children }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const checkIfLoggedIn = (path) => {
		// Checking if it is dashboard routes or not
		if (path.indexOf(DASHBOARD_PREFIX) > -1) {
			getCookie("token")
				.then((res) => {
					if (res && res !== "") {
						getLocalStorage("authUser").then((email) => {
							if (email !== "") {
								if (path.indexOf("login") > -1) {
									window.location.href = DASHBOARD_PREFIX;
								} else {
									return;
								}
							} else {
								logOut();
							}
						});
					} else {
						logOut();
					}
				})
				.catch(() => {
					logOut();
				});
		}
	};

	const get_cookie = (name) => {
		return document.cookie.split(";").some((c) => {
			return c.trim().startsWith(name + "=");
		});
	};

	const delete_cookie = (name, path, domain) => {
		if (get_cookie(name)) {
			document.cookie =
				name +
				"=" +
				(path ? ";Path=" + path : "") +
				// (domain ? ";Domain=" + domain : "") +
				";expires=Thu, 01 Jan 1970 00:00:01 GMT";
		}
	};

	const logOut = () => {
		const host = window.location.hostname;
		delete_cookie("token", "/", host);
		localStorage.removeItem("authUser");
		navigate("/admin-dashboard/login");
	};

	useEffect(() => {
		checkIfLoggedIn(pathname);
	}, [pathname]);

	return <AuthContext.Provider value={{ logOut }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
