import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { useDashboardContext } from "../../../dashboardContext";
import { Icon } from "@iconify/react";
import { doc, getDoc, Timestamp, updateDoc } from "firebase/firestore";
import db from "../../../../firebase-config";
import { deleteFiles, saveFiles } from "../../utils";

const REDIRECT = "/admin-dashboard/our-teams";
const COLLECTION_NAME = "teams";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function EditMember() {
	const { setTitle, setUpdating } = useDashboardContext();

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const { id } = useParams();
	const [team, setTeam] = useState(null);

	const teamsRef = doc(db, COLLECTION_NAME, id);

	const getContent = async () => {
		try {
			const docSnap = await getDoc(teamsRef);
			if (docSnap.exists()) {
				setTeam(docSnap.data());
			} else {
				setTeam({});
			}
		} catch (error) {
			setTeam({});
		}
	};

	const [image, setImage] = useState({});

	const handleFileInput = (evt) => {
		const file = evt.target.files[0];

		if (file && evt.target.files.length >= 1) {
			const ext = file.name.substr(file.name.lastIndexOf(".") + 1);
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onloadend = (e) => {
				setImage({ uri: reader.result, ext });
			};
		} else {
			return;
		}
	};

	const updateTeamDoc = async (imageUpdated = false) => {
		const data = { ...team };
		if (imageUpdated) {
			const { url, path } = (await saveFiles([image], COLLECTION_NAME))[0];
			team.image = url;
			team.imagePath = path;
		}
		await updateDoc(teamsRef, {
			...data,
			updatedAt: Timestamp.now(),
		})
			.then(async () => {
				setUpdating(false);
				setTimeout(() => {
					window.location.href = REDIRECT + "/" + id;
				}, 100);
			})
			.catch((error) => {
				throw new Error(error);
			});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			if (Object.keys(image).length > 0 && (image.uri != null || image.uri !== "")) {
				await deleteFiles([{ path: team.imagePath }]);
				await updateTeamDoc(true);
			}

			await updateTeamDoc();
			setUpdating(false);
		} catch (error) {
			console.error(error);
			setUpdating(false);
		}
	};

	useEffect(() => {
		getContent();
	}, [id]);

	useEffect(() => {
		setTitle("Edit member details");
	}, []);

	return team ? (
		Object.keys(team).length > 0 ? (
			<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
				{/* Top App Bar */}
				<div className="flex items-center justify-between gap-10">
					<div className="flex items-center gap-4">
						<div
							role="link"
							onClick={() => navigate(-1)}
							className="hover:scale-110 duration-200 text-xl cursor-pointer"
						>
							<Icon icon="solar:arrow-left-line-duotone" />
						</div>

						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
							Edit Team Profile Details
						</h1>
					</div>
				</div>

				{/* Bottom Form */}
				<form
					action={pathname ?? "#"}
					method="POST"
					className="grid gap-12 tracking-tighter"
					onSubmit={handleSubmit}
				>
					<div className="w-full grid grid-cols-1 lxs:grid-cols-2 sm:grid-cols-3 gap-12 duration-300">
						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="firstname"
								className="font-medium text-sm"
							>
								<span>First name</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<FormInput
									type="text"
									required
									id="firstname"
									pattern="[a-zA-Z]+"
									autoComplete="given-name"
									placeholder="Enter first name"
									defaultValue={team?.firstname ?? ""}
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setTeam((t) => ({
											...t,
											firstname: val.length > 0 ? val : "",
										}));
									}}
								/>
							</motion.div>
						</div>

						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="middlename"
								className="font-medium text-sm"
							>
								<span>Middle name</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<FormInput
									type="text"
									id="middlename"
									pattern="[a-zA-Z]+"
									autoComplete="additional-name"
									placeholder="Enter middle name"
									defaultValue={team?.middlename ?? ""}
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setTeam((t) => ({
											...t,
											middlename: val.length > 0 ? val : "",
										}));
									}}
								/>
							</motion.div>
						</div>

						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="lastname"
								className="font-medium text-sm"
							>
								<span>Last name</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<FormInput
									type="text"
									required
									id="lastname"
									pattern="[a-zA-Z]+"
									autoComplete="family-name"
									placeholder="Enter last name"
									defaultValue={team?.lastname ?? ""}
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setTeam((t) => ({
											...t,
											lastname: val.length > 0 ? val : "",
										}));
									}}
								/>
							</motion.div>
						</div>
					</div>

					<div className="w-full grid grid-cols-1 lxs:grid-cols-2 sm:grid-cols-3 gap-12 duration-300">
						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="email"
								className="font-medium text-sm"
							>
								<span>Email address</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<FormInput
									type="email"
									id="email"
									required
									autoComplete="email"
									placeholder="Your email address"
									defaultValue={team?.email ?? ""}
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setTeam((t) => ({
											...t,
											email: val.length > 0 ? val : "",
										}));
									}}
								/>
							</motion.div>
						</div>

						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="phone"
								className="font-medium text-sm"
							>
								<span>Mobile Number</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<FormInput
									type="text"
									id="phone"
									required
									maxLength={15}
									autoComplete="tel"
									placeholder="Enter contact number"
									defaultValue={team?.phone ?? ""}
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setTeam((t) => ({
											...t,
											phone: val.length > 0 ? val : "",
										}));
									}}
								/>
							</motion.div>
						</div>

						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="gender"
								className="font-medium text-sm"
							>
								<span>Gender</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<select
									id="gender"
									required
									className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
									defaultValue={team?.gender ?? ""}
									onChange={(e) => {
										const val = parseInt(e.currentTarget.value);
										setTeam((t) => ({
											...t,
											gender: val,
										}));
									}}
								>
									<option value="" key="-1" disabled>
										Choose gender
									</option>
									<option value="0" key="0">
										Male
									</option>
									<option value="1" key="1">
										Female
									</option>
									<option value="2" key="2">
										Other
									</option>
								</select>
							</motion.div>
						</div>
					</div>

					<div className="w-full grid grid-cols-1 lxs:grid-cols-2 sm:grid-cols-3 gap-12 duration-300">
						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								htmlFor="position_role"
								className="font-medium text-sm"
							>
								<span>Position/Role</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3 }}
								className="w-full h-11"
							>
								<FormInput
									type="text"
									required
									id="position_role"
									autoComplete="off"
									placeholder="Enter position/role"
									defaultValue={team?.position ?? ""}
									onKeyUp={(e) => {
										const val = e.currentTarget.value;
										setTeam((t) => ({
											...t,
											position: val.length > 0 ? val : "",
										}));
									}}
								/>
							</motion.div>
						</div>
					</div>

					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="image"
							className="font-medium text-sm"
						>
							<span>Update Profile Image</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full"
						>
							<input
								type="file"
								id="image"
								aria-label="Image"
								alt="Choose image"
								onChange={handleFileInput}
								accept="image/x-png, image/jpeg, image/webp, image/gif"
								className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
							/>
						</motion.div>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-40 h-40 rounded border bg-white flex items-center justify-center overflow-hidden"
						>
							<img
								src={team.image ?? "/static/images/default.jpg"}
								alt={team.name ?? "profile"}
								className="w-auto h-auto object-cover bg-gray-50"
							/>
						</motion.div>
					</div>

					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="description"
							className="font-medium text-sm"
						>
							<span>Member Description</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-auto"
						>
							<textarea
								rows={10}
								required
								id="description"
								className="w-full text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								placeholder="Write description about team member..."
								defaultValue={team?.description ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setTeam((t) => ({
										...t,
										description: val.length > 0 ? val : "",
									}));
								}}
							></textarea>
						</motion.div>
					</div>

					<div className="place-self-end flex items-center gap-6 flex-wrap">
						{/* Go back */}
						<button
							type="button"
							onClick={() => navigate(-1)}
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
						>
							<Icon icon="solar:arrow-left-outline" fontSize={16} />
							<span>Go Back</span>
						</button>

						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update Member</span>
							<Icon icon="solar:add-square-bold" fontSize={16} />
						</button>
					</div>
				</form>
			</div>
		) : (
			<div className="w-full h-full p-10 flex items-center justify-center">
				<div className="grid justify-items-center gap-4">
					<div className="text-8xl text-orange-500">
						<Icon icon="solar:planet-bold-duotone" />
					</div>

					<span className="font-medium text-lg tracking-tighter">Member not found.</span>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading member details...
				</h1>
			</div>
		</div>
	);
}
