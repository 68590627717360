import React, { useEffect, useRef, useState } from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, EffectCreative } from "swiper/modules";

import "./style.css";
import "swiper/css/effect-creative";
import { Icon } from "@iconify/react";

export default function SlideShow({ images, title = "" }) {
	const [swiperKey, setSwiperKey] = useState(0);

	const [currentSlide, setCurrentSlide] = useState(0);

	const swiperRef = useRef();
	const prevBtnRef = useRef();
	const nextBtnRef = useRef();

	useEffect(() => {
		setSwiperKey(1);
		return () => {
			setSwiperKey(0);
		};
	}, []);

	return (
		<div className="w-full flex flex-col">
			<div className="w-full aspect-[5/3] rounded-t border border-gray-100 bg-white overflow-hidden duration-300">
				<div className="relative w-full h-full flex items-center justify-center overflow-hidden">
					{/* Previous slide */}
					<div
						aria-label="Previous"
						role="button"
						ref={prevBtnRef}
						className="absolute z-10 left-4 w-9 h-9 min-h-9 min-w-9 rounded-full bg-black/30 text-gray-400 hover:text-white flex items-center justify-center cursor-pointer duration-300"
					>
						<Icon icon="solar:arrow-left-outline" fontSize={20} />
					</div>

					<Swiper
						key={swiperKey}
						ref={swiperRef}
						keyboard={{
							enabled: true,
						}}
						slidesPerView={1}
						slidesPerGroup={1}
						navigation={{
							prevEl: prevBtnRef.current,
							nextEl: nextBtnRef.current,
						}}
						onSlideChange={({ activeIndex }) => setCurrentSlide(activeIndex)}
						grabCursor={false}
						effect={"creative"}
						creativeEffect={{
							prev: {
								shadow: true,
								translate: ["-20%", 0, -1],
							},
							next: {
								translate: ["100%", 0, 0],
							},
						}}
						modules={[Autoplay, EffectCreative, Navigation]}
						autoplay={{
							delay: 5000,
							disableOnInteraction: false,
						}}
					>
						{images.map((image, i) => (
							<SwiperSlide key={i}>
								<img src={image.url} alt={title} className="w-full h-full object-cover" />
							</SwiperSlide>
						))}
					</Swiper>

					{/* Next slide */}
					<div
						aria-label="Next"
						role="button"
						ref={nextBtnRef}
						className="absolute z-10 right-4 w-9 h-9 min-h-9 min-w-9 rounded-full bg-black/30 text-gray-400 hover:text-white flex items-center justify-center cursor-pointer duration-300"
					>
						<Icon icon="solar:arrow-right-outline" fontSize={20} />
					</div>
				</div>
			</div>

			{/* Other images */}
			<div className="w-full h-24 rounded-b border border-t-0 border-gray-100 bg-white overflow-auto">
				<div className="h-full flex flex-nowrap items-start justify-start *:h-full *:w-24 divide-x divide-gray-100 min-w-max">
					{images.map((image, index) => (
						<div className="relative" key={index}>
							<img src={image.url} alt={title} className="w-full h-full object-cover" />

							<div
								className={
									"absolute z-10 inset-0 duration-300 cursor-pointer " +
									(currentSlide === index ? "bg-transparent" : "bg-white/40")
								}
								onClick={() => {
									if (swiperRef.current) {
										swiperRef.current.swiper.slideTo(index, 300);
										setCurrentSlide(index);
									}
								}}
							></div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
