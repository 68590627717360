import React from "react";
import { motion } from "framer-motion";
import moment from "moment";

export default function Property({ prefix, property, delay }) {
	return (
		<motion.div
			initial={{ opacity: 0, y: -6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3, delay: delay }}
			className="relative group/property duration-300"
		>
			<div className="w-44 h-auto rounded-sm border border-gray-100 hover:border-indigo-200 overflow-hidden bg-white hover:bg-indigo-50/20 duration-300">
				<a href={`${prefix}/${property.id}`} className="w-full aspect-[2/1]">
					<div className="w-full h-full border-b  overflow-hidden">
						<img
							src={property.images[0].url ?? "/static/images/placeholder.jpg"}
							alt={property.title ?? "Property"}
							className="w-full aspect-[2/1] object-cover transition-all duration-300"
						/>
					</div>
				</a>

				{/* Property contents */}
				<div className="p-2 grid gap-2 justify-items-start">
					<div className="block space-y-2 text-left">
						<div className="flex flex-col items-start -space-y-1">
							<a
								href={`${prefix}/${property.id}`}
								className="text-base tracking-tight font-bold line-clamp-1 text-ellipsis text-pretty group-hover/property:text-orange-600 duration-200"
							>
								{property.title ?? "Unknown"}
							</a>

							<a
								href="https://www.google.com/maps"
								rel="noreferrer"
								target="_blank"
								className="text-gray-500 text-lsm font-medium text-ellipsis line-clamp-1"
							>
								{property.loc.name ?? ""}
							</a>
						</div>

						<div className="flex flex-col items-start text-sm font-medium text-gray-600">
							{moment(property.createdAt.seconds * 1000).format("MMM DD, hh:mm a")}
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
