import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useDashboardContext } from "../../dashboardContext";
import { collection, deleteDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import db from "../../../firebase-config";
import moment from "moment";
import ViewMessage from "./view";
import { deleteMessage } from "./actions";

export default function Messages() {
	const { setTitle, setUpdating } = useDashboardContext();
	const [messages, setMessages] = useState(null);

	const { pathname } = useLocation();

	const collectionRef = collection(db, "messages");

	const getMessages = async () => {
		try {
			const q = query(collectionRef, orderBy("sentAt", "desc"), limit(100));
			const docsSnap = await getDocs(q);
			if (docsSnap.empty) {
				setMessages([]);
			} else {
				setMessages(docsSnap.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
			}
		} catch (error) {
			setMessages({});
		}
	};

	useEffect(() => {
		getMessages();
		setTitle("Messages");
	}, []);

	const [selectedItems, setSelectedItems] = useState([]);
	const handleCheckboxChange = (event) => {
		const { value, checked } = event.target;
		setSelectedItems((prev) => {
			if (checked) {
				return [...prev, value];
			} else {
				return prev.filter((item) => item !== value);
			}
		});
	};

	const deleteSelected = async () => {
		const confirmed = window.confirm("Are you sure to delete all the selected items?");
		if (confirmed) {
			setUpdating(true);
			try {
				const q = query(collectionRef, where("id", "in", selectedItems));
				await getDocs(q)
					.then(async (res) => {
						await Promise.all(
							res.docs.map((doc) => {
								return deleteDoc(doc.ref);
							})
						);

						setUpdating(false);
						getMessages();
						setSelectedItems([]);
					})
					.catch((error) => {
						console.log(error);
						setUpdating(false);
					});
			} catch (error) {
				setUpdating(false);
				console.log(error);
			}
		}
	};

	return messages ? (
		<div className="relative h-screen w-full py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 flex items-center flex-col duration-300">
			{/* Absolute button to delete selected */}
			{selectedItems.length > 0 && (
				<div className="absolute bottom-4 z-50">
					<div
						role="button"
						onClick={deleteSelected}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-rose-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Delete selected</span>
						<Icon icon="solar:trash-bin-minimalistic-bold-duotone" fontSize={16} />
					</div>
				</div>
			)}

			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10 mb-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Messages
					</h1>

					{messages.length > 0 && (
						<div className="text-sm tracking-tight text-gray-600">
							Total <span className="font-semibold">{messages.length}</span> messages found.
						</div>
					)}
				</div>

				{/* {messages.length > 0 && (
					<button
						onClick={async () => {
							const confirmed = window.confirm("Are you sure you want to delete?");
							if (confirmed) {
								await deleteAllData("messages")
									.then((res) => alert(res))
									.catch((error) => {
										alert("Failed to delete");
										console.error(error);
									});
							} else return;
						}}
						className="border-0 outline-none rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-rose-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Delete all messages</span>
						<Icon icon="solar:trash-bin-minimalistic-bold-duotone" fontSize={16} />
					</button>
				)} */}
			</div>

			{messages.length > 0 ? (
				<div className="w-full h-auto overflow-auto">
					<table className="w-full h-full min-w-max overflow-auto border-collapse">
						<thead className="w-full text-sm tracking-tighter border-b">
							<tr className="text-left *:px-4">
								<th>
									<input
										type="checkbox"
										value={""}
										id={"appraisalSelectAll"}
										onChange={(event) => {
											const { checked } = event.target;
											if (checked) {
												setSelectedItems(
													messages.map((a) => {
														return a.id;
													})
												);
											} else {
												setSelectedItems([]);
											}
										}}
										checked={selectedItems.length === messages.length}
									/>
								</th>
								<th className="py-2">SN</th>
								<th>Full Name</th>
								<th>Email Address</th>
								<th>Phone</th>
								<th>Subject</th>
								<th>Message</th>
								<th>Sent At</th>
								<th>Actions</th>
							</tr>
						</thead>

						<tbody>
							{messages.map((message, index) => (
								<tr
									key={index}
									className={
										"text-left text-xsm hover:bg-gray-100 even:bg-gray-50 *:px-4 " +
										(message.viewed ? "" : "bg-green-50")
									}
								>
									<td>
										<input
											type="checkbox"
											value={message.id}
											id={"messageSelected" + index}
											name={"message" + index}
											onChange={handleCheckboxChange}
											checked={selectedItems.includes(message.id)}
										/>
									</td>

									<td align="middle">
										<div
											className={
												"w-6 h-6 rounded-full flex items-center justify-center " +
												(message.viewed ? "" : "bg-green-500 text-white")
											}
										>
											<span className="font-medium text-sm">{index + 1}</span>
										</div>
									</td>

									<td className="py-4 font-semibold">
										<a href={pathname + "/" + message.id} className="hover:text-blue-600">
											{message.fullname ?? ""}
										</a>
									</td>
									<td>
										<a href={"mailTo:" + (message.email ?? "")} className="font-semibold min-w-max">
											{message.email ?? ""}
										</a>
									</td>
									<td className="font-medium">
										<a href={"tel:" + (message.mobile ?? "")} className="min-w-max">
											{message.mobile ?? ""}
										</a>
									</td>
									<td className="font-semibold">
										<div className="line-clamp-1 text-ellipsis max-w-72">
											{message.subject ?? ""}
										</div>
									</td>
									<td>
										<div className="line-clamp-1 text-ellipsis max-w-96 font-medium">
											{message.message ?? ""}
										</div>
									</td>
									<td>
										<div className="min-w-max font-medium">
											{moment(message.sentAt.seconds * 1000).format("MMM DD, hh:mm a")}
										</div>
									</td>
									<td>
										<div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
											<div
												role="button"
												onClick={() => deleteMessage(message.id)}
												className="hover:text-red-600 cursor-pointer"
												title="Delete message"
											>
												<Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
											</div>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</div>
			) : (
				<div className="w-full flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:users-group-rounded-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>Couldn't found any messages.</span>
						</div>
					</div>
				</div>
			)}
		</div>
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading messages...
				</h1>
			</div>
		</div>
	);
}

export { ViewMessage };
