import React from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";

export default function Empty() {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
			className="w-full col-span-full rounded bg-rose-50 p-10 flex items-center justify-center duration-200"
		>
			<div className="flex flex-col gap-4 items-center">
				<motion.div
					initial={{ opacity: 0, y: -6 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2 }}
					className="text-7xl text-rose-500"
				>
					<Icon icon="solar:buildings-3-bold-duotone" />
				</motion.div>

				<motion.h4
					initial={{ opacity: 0, y: 6 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2 }}
					className="text-sm font-semibold tracking-tight"
				>
					No search results found.
				</motion.h4>
			</div>
		</motion.div>
	);
}
