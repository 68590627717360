import React from "react";
import { motion } from "framer-motion";
import moment from "moment";

export default function News({ prefix, news, delay }) {
	return (
		<motion.div
			initial={{ opacity: 0, y: -6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3, delay: delay }}
			className="relative group/news duration-300"
		>
			<div className="w-44 h-auto rounded-sm border border-gray-100 hover:border-indigo-200 overflow-hidden bg-white hover:bg-indigo-50/20 duration-300">
				<a href={`${prefix}/${news.id}`} className="w-full aspect-[2/1]">
					<div className="w-full h-full border-b  overflow-hidden">
						<img
							src={news.image.url ?? "/static/images/placeholder.jpg"}
							alt={news.title ?? "News"}
							className="w-full aspect-[2/1] object-cover transition-all duration-300"
						/>
					</div>
				</a>

				{/* News contents */}
				<div className="p-2 grid gap-2 justify-items-start">
					<div className="block space-y-2 text-left">
						<div className="flex flex-col items-start -space-y-1">
							<a
								href={`${prefix}/${news.id}`}
								className="text-base tracking-tight font-bold line-clamp-1 text-ellipsis text-pretty group-hover/news:text-orange-600 duration-200"
							>
								{news.title ?? "Unknown"}
							</a>

							<div className="flex flex-col items-start text-sm font-medium text-gray-600">
								{moment(news.createdAt.seconds * 1000).format("MMM DD, hh:mm a")}
							</div>
						</div>

						<div
							className="text-xsm tracking-tighter line-clamp-4 text-ellipsis text-pretty"
							dangerouslySetInnerHTML={{ __html: news.content ?? "" }}
						></div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
