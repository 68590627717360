import { deleteDoc, doc, increment, updateDoc } from "firebase/firestore";
import db, { storage } from "../../../firebase-config";
import { deleteObject, ref } from "firebase/storage";

export function deleteNews(news) {
	return new Promise(async (resolve, reject) => {
		const deletingNewsRef = ref(storage, news.image.path);
		const newsDoc = doc(db, "news", news.id);
		await deleteObject(deletingNewsRef)
			.then(async () => {
				await deleteDoc(newsDoc)
					.then(() => resolve(true))
					.catch(() => reject(false));
			})
			.catch(async (error) => {
				const confirmed = window.confirm(
					"Something went wrong while deleting image. Invalid file path or file not found.\nDo you want to continue deletion?"
				);
				if (confirmed) {
					deleteDoc(newsDoc)
						.then(async () => {
							const counterRef = doc(db, "counters", "news");
							await updateDoc(counterRef, {
								count: increment(-1),
							});
							resolve(true);
						})
						.catch(() => reject(false));
				} else {
					resolve(true);
				}
			});
	});
}
