import { doc, deleteDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import { updateCount } from "..";

const REDIRECT = "/admin-dashboard/messages";

export const deleteMessage = async (id, redirect = false) => {
	const confirmed = window.confirm("Are you sure you want to delete this message?");

	if (confirmed) {
		const ref = doc(db, "messages", id);
		await deleteDoc(ref)
			.then(async () => {
				await updateCount("messages");
				alert("Deleted");
				if (redirect) {
					window.location.href = REDIRECT;
				} else {
					window.location.reload();
				}
			})
			.catch(() => alert("Failed to delete."));
	}
};
