import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { motion } from "framer-motion";
import { useDashboardContext } from "../dashboardContext";
import SideBar from "./sidebar";
import { Icon } from "@iconify/react";
import "./style.css";
import { useAuth } from "../hooks/AuthProvider";

export default function AdminDashboardLayout() {
	const { user, title, updating } = useDashboardContext();
	const { logOut } = useAuth();

	useEffect(() => {
		document.title = (title ?? "Dashboard") + " - " + process.env.REACT_APP_APP_NAME;
	}, [title]);

	return (
		<div className="relative w-screen h-screen bg-white text-black flex overflow-hidden">
			{updating ? (
				<motion.div
					initial={{ opacity: 0 }}
					animate={{ opacity: 1 }}
					transition={{ duration: 0.3 }}
					className="fixed z-9999 w-screen h-screen inset-0 bg-black/40 flex items-center justify-center duration-300"
				>
					<div className="grid justify-items-center gap-4">
						<motion.div initial={{ y: -20 }} animate={{ y: 0 }} transition={{ duration: 0.3 }}>
							<div className="text-3xl text-blue-100 animate-spin">
								<Icon icon="solar:black-hole-line-duotone" />
							</div>
						</motion.div>

						<motion.div initial={{ y: 20 }} animate={{ y: 0 }} transition={{ duration: 0.3 }}>
							<div className="text-white font-medium text-sm uppercase duration-300">
								<span>Updating</span>
							</div>
						</motion.div>
					</div>
				</motion.div>
			) : null}

			<div className="min-w-max max-w-60 h-full border-r bg-gray-50">
				<SideBar user={user} logOut={logOut} />
			</div>

			<div className="w-full h-full overflow-auto">
				<Outlet />
			</div>
		</div>
	);
}
