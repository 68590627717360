import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDashboardContext } from "../../dashboardContext";
import { Icon } from "@iconify/react";
import { collection, doc, getDoc, getDocs, Timestamp, updateDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import { deleteFiles, saveFiles } from "../utils";

const REDIRECT = "/admin-dashboard/all-reviews";
const COLLECTION_NAME = "reviews";

const FormInput = ({ ...rest }) => {
	return (
		<input
			{...rest}
			className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
		/>
	);
};

export default function EditReview() {
	const { setTitle, setUpdating } = useDashboardContext();

	const [agents, setAgents] = useState([]);
	const [data, setData] = useState(null);

	const { id } = useParams();
	const reviewRef = doc(db, COLLECTION_NAME, id);

	const navigate = useNavigate();
	const { pathname } = useLocation();

	const [agentSelected, setAgentSelected] = useState(null);

	const [image, setImage] = useState({});
	const handleFileInput = (evt) => {
		const file = evt.target.files[0];

		if (file && evt.target.files.length >= 1) {
			const ext = file.name.substr(file.name.lastIndexOf(".") + 1);
			const reader = new FileReader();

			reader.readAsDataURL(file);

			reader.onloadend = (e) => {
				setImage({ uri: reader.result, ext });
			};
		} else {
			return;
		}
	};

	const updateReviewDoc = async (imageUpdated = false) => {
		const __data = { ...data };
		if (imageUpdated) {
			const { url, path } = (await saveFiles([image], COLLECTION_NAME))[0];
			__data.image = { url, path };
		}
		if (agentSelected != null) {
			const agentRef = doc(db, "teams", agentSelected);
			__data.agent = agentRef;
		}
		await updateDoc(reviewRef, {
			...__data,
			updatedAt: Timestamp.now(),
		})
			.then(async () => {
				setUpdating(false);
				setTimeout(() => {
					window.location.href = REDIRECT;
				}, 100);
			})
			.catch((error) => {
				throw new Error(error);
			});
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setUpdating(true);

		try {
			if (Object.keys(image).length > 0 && (image.uri != null || image.uri !== "")) {
				try {
					await deleteFiles([{ path: data.image.path }]);
				} catch (error) {}
				await updateReviewDoc(true);
			}
			await updateReviewDoc();
		} catch (error) {
			console.error(error);
			setUpdating(false);
		}
	};

	const getData = async () => {
		try {
			const docSnap = await getDoc(reviewRef);
			if (docSnap.exists()) {
				const __agentRef = docSnap.data().agent;
				await getDoc(__agentRef).then((a) => {
					if (a.exists()) {
						setAgentSelected(a.id);
					}
				});
				setData({ ...docSnap.data() });
			} else {
				setData({});
			}
		} catch (error) {
			setData({});
		}
	};

	const getAgents = async () => {
		const ref = collection(db, "teams");
		await getDocs(ref).then((res) => {
			if (res.empty) return;
			setAgents(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
		});
	};

	useEffect(() => {
		getData();
		setTitle("Edit Review");
		getAgents();
	}, [id]);

	return data ? (
		Object.keys(data).length > 0 ? (
			<div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
				{/* Top App Bar */}
				<div className="flex items-center justify-between gap-10">
					<div className="flex items-center gap-4">
						<div
							role="link"
							onClick={() => navigate(-1)}
							className="hover:scale-110 duration-200 text-xl cursor-pointer"
						>
							<Icon icon="solar:arrow-left-line-duotone" />
						</div>

						<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
							Create Reviews
						</h1>
					</div>
				</div>

				{/* Bottom Form */}
				<form
					action={pathname ?? "#"}
					method="POST"
					className="grid gap-12 tracking-tighter"
					onSubmit={handleSubmit}
				>
					{/* Reviews Title */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="username"
							className="font-medium text-sm"
						>
							<span>Full Name</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-11"
						>
							<FormInput
								type="text"
								id="username"
								autoComplete="off"
								required
								placeholder="Full Name"
								defaultValue={data.username ?? ""}
								onKeyUp={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										username: val.length > 0 ? val : "",
									}));
								}}
							/>
						</motion.div>
					</div>

					{/* Number of Stars */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3, ease: "easeInOut" }}
							htmlFor="stars"
							className="font-medium text-sm"
						>
							<span>Number of Stars</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3, ease: "easeOut" }}
							className="w-full h-11"
						>
							<select
								id="stars"
								required
								className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								defaultValue={data.stars ?? ""}
								onChange={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										stars: val,
									}));
								}}
							>
								<option value="" key="-1" disabled>
									Select total stars
								</option>
								{Array.from({ length: 5 }, () => Array("1").fill("1")).map((s, i) => (
									<option value={i + 1} key={i}>
										{i + 1} stars
									</option>
								))}
							</select>
						</motion.div>
					</div>

					{/* Reviews Images */}
					<motion.div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="image"
							className="font-medium text-sm"
						>
							<span>Property Image</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full"
						>
							<input
								type="file"
								id="image"
								accept="image/x-png, image/jpeg, image/webp, image/gif"
								className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
								onChange={handleFileInput}
							/>
						</motion.div>

						<div className="w-40 h-40 rounded border bg-white flex items-center justify-center overflow-hidden">
							<img
								src={data.image.url ?? "/static/images/placeholder.jpg"}
								alt={"property"}
								className="w-auto h-auto object-cover bg-gray-50"
							/>
						</div>
					</motion.div>

					{/* Agent */}
					<div className="space-y-10">
						<h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
							Contact
						</h1>

						<div className="w-full grid gap-2 justify-items-start">
							<motion.label
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3, ease: "easeInOut" }}
								htmlFor="agent"
								className="font-medium text-sm"
							>
								<span>Agent</span>
							</motion.label>

							<motion.div
								initial={{ opacity: 0, y: -20 }}
								animate={{ opacity: 1, y: 0 }}
								transition={{ duration: 0.3, ease: "easeInOut" }}
								className="w-full h-11"
							>
								<select
									id="agent"
									required
									className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-2 focus-within:border-orange-500 focus:border-orange-500 bg-gray-50 placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
									defaultValue={agentSelected ?? ""}
									onChange={(e) => {
										const val = e.currentTarget.value;
										setAgentSelected(val);
									}}
								>
									<option value="" key="-1" disabled>
										Choose agent
									</option>
									{agents.map((agent, i) => (
										<option key={i} value={agent.id}>
											{(agent.firstname ?? "") +
												" " +
												(agent.middlename ?? "") +
												" " +
												(agent.lastname ?? "")}
										</option>
									))}
								</select>
							</motion.div>
						</div>
					</div>

					{/* Reviews Contents */}
					<div className="w-full grid gap-2 justify-items-start">
						<motion.label
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							htmlFor="desc"
							className="font-medium text-sm"
						>
							<span>Review</span>
						</motion.label>

						<motion.div
							initial={{ opacity: 0, y: -20 }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ duration: 0.3 }}
							className="w-full h-auto"
						>
							<textarea
								rows={10}
								id="desc"
								required
								className="w-full text-sm font-medium rounded-sm tracking-tighter outline-none py-2 px-4 border-2 focus-within:border-orange-500 focus:border-orange-500 bg-white placeholder:text-gray-500 placeholder:font-normal duration-300 ease-linear"
								placeholder="Write review..."
								defaultValue={data.desc ?? ""}
								onChange={(e) => {
									const val = e.currentTarget.value;
									setData((d) => ({
										...d,
										desc: val,
									}));
								}}
							></textarea>
						</motion.div>
					</div>

					<div className="place-self-end flex items-center gap-6 flex-wrap">
						<button
							type="reset"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
						>
							<span>Reset</span>
							<Icon icon="solar:close-square-outline" fontSize={16} />
						</button>

						<button
							type="submit"
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update Review</span>
							<Icon icon="solar:add-square-bold" fontSize={16} />
						</button>
					</div>
				</form>
			</div>
		) : (
			<div className="w-full h-full p-10 flex items-center justify-center">
				<div className="grid justify-items-center gap-4">
					<div className="text-8xl text-orange-500">
						<Icon icon="solar:archive-minimalistic-bold-duotone" />
					</div>

					<span className="font-medium text-lg tracking-tighter">Review not found.</span>
				</div>
			</div>
		)
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading review details...
				</h1>
			</div>
		</div>
	);
}
