import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import SearchResults from "./results";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import db from "../../firebase-config";

const cities = [
	"Sydney",
	"Canberra",
	"Gold Coast",
	"Darwin",
	"Cairns",
	"Hobart",
	"Brisbane",
	"Melbourne",
	"Perth",
];

export default function SearchProperty() {
	const searchInputRef = useRef(null);
	const searchPageBannerRef = useRef(null);

	const navigate = useNavigate();

	const [city, setCity] = useState(null);

	const [params, setParams] = useSearchParams();
	const location = useLocation();

	const [searchQuery, setSearchQuery] = useState("");
	const [properties, setPropperties] = useState([]);

	const searchProperties = async (event) => {
		if (event.keyCode === 13) {
			const search = event.currentTarget.value;
			setParams((p) => ({
				...p,
				search: search,
			}));

			let ref = collection(db, "properties");
			if (city) {
				ref = query(ref, where("city", "==", city));
			}
			ref = query(ref, where("title", "==", search), limit(30));
			await getDocs(ref)
				.then((res) => {
					if (res.empty) {
						setPropperties([]);
					} else {
						setPropperties(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
					}
				})
				.catch((error) => {
					console.error(error);
					setPropperties([]);
				});
		} else return;
	};

	useEffect(() => {
		if (params.has("search")) {
			setSearchQuery(params.get("search"));
		}
	}, [location]);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.2 }}
			className="w-full h-auto grid gap-0 bg-gradient-to-br from-orange-500 to-yellow-400"
		>
			<div
				ref={searchPageBannerRef}
				className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-200 p-14 space-y-10 grid"
			>
				<motion.div
					initial={{ opacity: 0, y: -6 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2 }}
					className="flex items-center justify-between gap-10"
				>
					<div className="flex items-center gap-4 text-white">
						<div
							role="link"
							onClick={() => navigate(-1)}
							className="hover:scale-110 duration-200 text-xl cursor-pointer"
						>
							<Icon icon="solar:arrow-left-line-duotone" />
						</div>

						<h4 className="font-semibold text-lg tracking-tight">Search properties</h4>
					</div>

					<div className="hidden lxs:block rounded-full h-10 bg-white border border-gray-100 overflow-hidden">
						<select
							id="city"
							required
							className="w-full h-full tracking-tighter rounded-sm text-sm font-medium px-4 outline-none border-0 bg-white duration-300 ease-linear"
							onChange={(e) => {
								const val = e.currentTarget.value;
								setCity(val);
							}}
						>
							<option value="" key={"-1"} disabled selected>
								Choose city
							</option>
							{cities.map((city, i) => (
								<option value={city} key={i}>
									{city}
								</option>
							))}
						</select>
					</div>
				</motion.div>

				{/* Search input form */}
				<motion.div
					initial={{ opacity: 0, y: 6 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0.2 }}
					id="searchProperyForm"
					className="grid gap-2 justify-items-start"
				>
					{city ? (
						<div className="text-xsm font-medium text-gray-200">
							<span>Searching in </span>
							<span className="font-semibold text-white">&nbsp;{city}&nbsp;</span>
							<span>city</span>
						</div>
					) : null}

					<div className="relative w-full h-11 duration-200 flex items-center">
						<input
							type="text"
							id="searchInputField"
							ref={searchInputRef}
							placeholder="Search property	(Press `Enter` to search)"
							autoFocus
							name="search"
							autoComplete="off"
							autoCorrect="off"
							spellCheck="false"
							defaultValue={searchQuery}
							onKeyDown={searchProperties}
							className="w-full h-full pl-12 peer bg-white rounded-md pr-4 outline-none ring-0 font-semibold placeholder:font-medium text-xsm duration-300"
						/>

						<div className="absolute z-10 left-4 text-lg text-gray-800 peer-focus:text-indigo-600 duration-200">
							<Icon icon="solar:magnifer-outline" />
						</div>
					</div>
				</motion.div>
			</div>

			<div className="w-full bg-white">
				<div className="w-full md:w-11/12 xl:w-10/12 mx-auto duration-200 px-2 py-10 space-y-10 grid">
					{properties ? (
						<SearchResults properties={properties} tag={city} />
					) : (
						<div className="p-4 bg-blue-50 rounded-xl flex items-center justify-start">
							<div>Searching properties...</div>
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
}
