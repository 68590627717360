import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ViewNews from "./view";
import AddNews from "./add";
import EditNews from "./edit";
import { Icon } from "@iconify/react";
import NewsItem from "./NewsItem";
import { useDashboardContext } from "../../dashboardContext";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import db from "../../../firebase-config";

export default function AllNews() {
	const { setTitle } = useDashboardContext();
	const { pathname } = useLocation();

	const [allNews, setAllNews] = useState(null);

	const getAllNews = async () => {
		const ref = collection(db, "news");
		const q = query(ref, orderBy("createdAt", "desc"), limit(50));
		const res = await getDocs(q);

		if (res.empty) {
			setAllNews([]);
		} else {
			setAllNews(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
		}
	};

	useEffect(() => {
		setTitle("All News");
		getAllNews();
	}, []);

	return allNews ? (
		<div className="h-full py-2 lxs:py-6 xsm:py-10 px-2 lxs:px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 flex flex-col duration-300">
			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						All News
					</h1>

					<div className="text-sm tracking-tight text-gray-600">
						{allNews?.length > 0 ? (
							<Fragment>
								Total <span className="font-medium text-gray-800">{allNews.length}</span> news found.
							</Fragment>
						) : null}
					</div>
				</div>

				<div className="flex items-center gap-4 lg:gap-6 duration-300">
					<a
						href={pathname + "/add"}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-blue-400 to-indigo-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Add news</span>
						<Icon icon="solar:add-square-bold" fontSize={16} />
					</a>
				</div>
			</div>

			{/* All News */}
			{allNews.length > 0 ? (
				<div className="w-full grid gap-6 duration-300">
					{allNews.map((news, index) => (
						<NewsItem key={index} news={news} index={index} pathname={pathname} />
					))}
				</div>
			) : (
				<div className="flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:notebook-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>News not found.</span>
						</div>
					</div>
				</div>
			)}
		</div>
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading news...
				</h1>
			</div>
		</div>
	);
}

export { ViewNews, AddNews, EditNews };
