import React, { useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";

function Images({ images = [], setImages, handleFileInput, deleteImage }) {
	return (
		<motion.div className="w-full grid gap-2 justify-items-start">
			<motion.label
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				htmlFor="images"
				className="font-medium text-sm"
			>
				<span>Images</span>
			</motion.label>

			<motion.div
				initial={{ opacity: 0, y: -20 }}
				animate={{ opacity: 1, y: 0 }}
				transition={{ duration: 0.3 }}
				className="w-full"
			>
				<input
					type="file"
					id="images"
					multiple
					required={images.length <= 0}
					onChange={handleFileInput}
					accept="image/x-png, image/jpeg, image/webp, image/gif"
					className="tracking-tighter rounded-sm text-sm font-medium outline-none border-0"
				/>
			</motion.div>

			<motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
				<SortOrder images={images} setImages={setImages} deleteImage={deleteImage} />
			</motion.div>
		</motion.div>
	);
}

const SortOrder = ({ images, setImages, deleteImage }) => {
	const [draggedOver, setDraggedOver] = useState(null);

	const handleDragStart = (e, card) => {
		e.dataTransfer.setData("cardId", card.id);
	};

	const handleDragEnd = (e) => {
		const cardId = parseInt(e.dataTransfer.getData("cardId"));
		const draggedOverId = parseInt(draggedOver.replace("card", ""));

		if (cardId !== draggedOverId && !isNaN(draggedOverId) && draggedOverId > -1) {
			let copy = [...images];

			const temp = copy[cardId];
			copy[cardId] = copy[draggedOverId];
			copy[draggedOverId] = temp;

			setImages([...copy]);
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		setDraggedOver(e.target.parentNode.id);
	};

	const handleDragLeave = () => {};

	return (
		<div className="w-full">
			<div
				onDrop={handleDragEnd}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				id="cardsContainer"
				className="h-auto w-full flex flex-wrap gap-4 transition-colors"
			>
				{images.map((c, index) => {
					return (
						<Card
							key={index}
							id={index}
							{...c}
							handleDragStart={handleDragStart}
							deleteImage={deleteImage}
						/>
					);
				})}
			</div>
		</div>
	);
};

const Card = ({ uri, url, id, handleDragStart, deleteImage }) => {
	return (
		<>
			<motion.div
				layout
				layoutId={id}
				draggable="true"
				data-column={"property"}
				id={"card" + id}
				onDragStart={(e) => handleDragStart(e, { id })}
				className="relative w-40 h-40 cursor-grab rounded border border-gray-300 bg-gray-50 flex items-center justify-center active:cursor-grabbing group/card"
			>
				<img
					src={uri ?? url ?? "/static/images/placeholder.jpg"}
					alt="property"
					className="w-full h-full object-cover"
				/>

				{/* Absolute delete image icon */}
				<div
					role="button"
					onClick={() => deleteImage(id)}
					className="absolute bottom-2 z-10 w-10 h-10 rounded-full bg-black bg-opacity-30 hover:bg-opacity-60 flex items-center justify-center text-gray-200 text-2xl hover:text-white cursor-pointer scale-0 opacity-0 group-hover/card:scale-100 group-hover/card:opacity-100 duration-300"
				>
					<Icon icon="solar:trash-bin-minimalistic-bold" />
				</div>
			</motion.div>
		</>
	);
};

export default Images;
