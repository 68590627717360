import React from "react";
import { Route, Routes } from "react-router-dom";
import AdminDashboardLayout from "./layout";
import { AboutUs, ContactUs, Dashboard, ExternalLinks, PrivacyPolicy, TermsAndConditions } from "./pages";
import DashboardContextProvider from "./dashboardContext";
import { AuthLogin } from "./auth";
import AuthProvider from "./hooks/AuthProvider";
import AllNews, { AddNews, EditNews, ViewNews } from "./pages/news";
import Properties, { AddProperty, EditProperty, ViewProperty } from "./pages/properties";
import OurTeams, { AddMember, EditMember, ViewMember } from "./pages/teams";
import Messages, { ViewMessage } from "./pages/messages";
import FreeAppraisals, { ViewFreeRequest } from "./pages/appraisals/free";
import RentalAppraisals, { ViewRentalRequest } from "./pages/appraisals/rental";
import SellPropertyRequests, { ViewSellRequest } from "./pages/appraisals/sellPropertyRequests";
import Reviews, { AddReview, EditReview } from "./pages/reviews";
import AuthUsers, { AddAuthUser, EditAuthUser } from "./pages/users";
import "./style.css";

export default function AdminRoutes() {
	return (
		<AuthProvider>
			<Routes>
				{/* Auth Routes */}
				<Route path="/login" element={<AuthLogin />} />
				{/* <Route path="/regiser" element={<AuthRegister />} /> */}

				<Route
					path="/"
					element={
						<DashboardContextProvider>
							<AdminDashboardLayout />
						</DashboardContextProvider>
					}
				>
					<Route index element={<Dashboard />} />

					<Route path="/messages" element={<Messages />} />
					<Route path="/messages/:id" element={<ViewMessage />} />

					{/* Auth Users */}
					<Route path="/users" element={<AuthUsers />} />
					<Route path="/users/add" element={<AddAuthUser />} />
					<Route path="/users/:id" element={<EditAuthUser />} />

					{/* Teams */}
					<Route path="/our-teams" element={<OurTeams />} />
					<Route path="/our-teams/add" element={<AddMember />} />
					<Route path="/our-teams/:id" element={<ViewMember />} />
					<Route path="/our-teams/:id/edit" element={<EditMember />} />

					{/* Properties */}
					<Route path="/all-properties" element={<Properties />} />
					<Route path="/all-properties/add" element={<AddProperty />} />
					<Route path="/all-properties/:id" element={<ViewProperty />} />
					<Route path="/all-properties/:id/edit" element={<EditProperty />} />

					{/* All News */}
					<Route path="/all-news" element={<AllNews />} />
					<Route path="/all-news/add" element={<AddNews />} />
					<Route path="/all-news/:id" element={<ViewNews />} />
					<Route path="/all-news/:id/edit" element={<EditNews />} />

					{/* All Reviews */}
					<Route path="/all-reviews" element={<Reviews />} />
					<Route path="/all-reviews/add" element={<AddReview />} />
					<Route path="/all-reviews/:id" element={<EditReview />} />

					{/* Appraisals */}
					<Route path="/free-appraisals" element={<FreeAppraisals />} />
					<Route path="/free-appraisals/:id" element={<ViewFreeRequest />} />
					<Route path="/rental-appraisals" element={<RentalAppraisals />} />
					<Route path="/rental-appraisals/:id" element={<ViewRentalRequest />} />
					<Route path="/sell-property-requests" element={<SellPropertyRequests />} />
					<Route path="/sell-property-requests/:id" element={<ViewSellRequest />} />

					{/* Other routes */}
					<Route path="/about-us" element={<AboutUs />} />
					<Route path="/contact-us" element={<ContactUs />} />
					<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
					<Route path="/privacy-policy" element={<PrivacyPolicy />} />
					<Route path="/external-links" element={<ExternalLinks />} />
				</Route>
			</Routes>
		</AuthProvider>
	);
}
