import React from "react";
import { motion } from "framer-motion";
import Property from "./property";

export default function RecentAddedProperties({ path, properties = [] }) {
	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
		>
			<div className="flex flex-col items-start gap-4 tracking-tighter">
				<motion.div
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, ease: "easeInOut" }}
					className="text-base text-slate-500"
				>
					<span>Recent added properties</span>
				</motion.div>

				<motion.div
					initial={{ opacity: 0, y: -20 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.3, delay: 0.1, ease: "easeOut" }}
					className="w-full bg-white flex items-start justify-start gap-8 pb-1 overflow-auto"
				>
					{properties.map((property, index) => (
						<Property
							key={index}
							property={property}
							delay={0.03 * index}
							prefix={path}
						/>
					))}
				</motion.div>
			</div>
		</motion.div>
	);
}
