import React from "react";
import { motion } from "framer-motion";
import moment from "moment";

export default function NewsBox({ news, prefix = "", delay }) {
	return (
		<motion.div
			initial={{ opacity: 0, scale: 0.6 }}
			animate={{ opacity: 1, scale: 1 }}
			transition={{ duration: 0.3, delay: delay }}
			className="relative w-full h-auto group/news"
		>
			<div className="w-full h-auto rounded-sm border border-gray-100 hover:border-orange-100 hover:bg-orange-50/20 duration-300 overflow-hidden bg-gray-50/50">
				<a href={prefix + "/" + news.id}>
					<img
						src={news.image.url ?? "/static/images/placeholder.jpg"}
						alt={news.title ?? "Unknown"}
						className="w-full aspect-[2/1] object-cover border-b"
					/>
				</a>

				{/* Property contents */}
				<div className="p-4 grid gap-4 justify-items-start">
					<div className="block space-y-4 text-left">
						<a href={prefix + "/" + news.id}>
							<h1 className="text-sm font-bold line-clamp-2 tracking-tight text-ellipsis text-pretty group-hover/news:text-orange-600 duration-200">
								{news.title ?? "Unknown"}
							</h1>
						</a>

						<div
							className="text-xsm font-medium text-gray-700 line-clamp-4 text-ellipsis text-pretty"
							dangerouslySetInnerHTML={{ __html: news?.content ?? "" }}
						></div>
					</div>

					<div className="text-xsm font-semibold">
						{moment(news.createdAt.seconds * 1000).format("MMM DD, YYYY")}
					</div>
				</div>
			</div>
		</motion.div>
	);
}
