import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import moment from "moment";
import { DashboardContext } from "../../dashboardContext";
import EditProperty from "./edit";
import ViewProperty from "./view";
import AddProperty from "./add";
import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  updateDoc,
} from "firebase/firestore";
import db from "../../../firebase-config";
import { deleteProperty, readXMLFiles } from "./actions";
import { Files, Images } from "./modals";
import axios from "axios";

const propertyTypes = [
  "Land",
  "House",
  "Apartment",
  "Studio",
  "Terrace",
  "Townhouse",
  "Unit",
  "Villa",
  "Block of Units",
];

const propertyStatus = ["Vacant", "Tenanted", "Owner occupied"];
const statusTypes = ["ACTIVE", "SOLD", "DELETED", "OFF_MARKET", "UNDER_OFFER"];
const COLLECTION_NAME = "properties";

export default function Properties() {
  const [modalData, setModalData] = useState({
    id: null,
    modal: null,
    addedFromXML: false,
    data: [],
  });

  const { setTitle, setUpdating } = useContext(DashboardContext);
  const [properties, setProperties] = useState(null);

  const { pathname } = useLocation();

  const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setSelectedItems((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const deleteSelected = async () => {
    if (process.env.NODE_ENV !== "production") {
      alert("You can't delete xml files while in development.");
    } else {
      const confirmed = window.confirm(
        "Are you sure to delete all the selected xml files?"
      );
      if (confirmed) {
        const deletePromises = selectedItems.map(async (filepath, _) => {
          try {
            await axios.post(
              "/backend/deleteFile.php",
              {
                filepath,
              },
              {
                headers: {
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              }
            );
          } catch (error) {
            console.error(`Error deleting file ${filepath}:`, error);
            throw error;
          }
        });

        Promise.all(deletePromises)
          .then(() => {
            setUpdating(true);
            alert("XML files deleted successfully.");
            window.location.reload();
          })
          .catch((error) => {
            console.error("Error deleting files:", error);
          })
          .finally(() => {
            setUpdating(false);
          });
      }
    }
  };

  const toggleStatus = async (id, status) => {
    try {
      const ref = doc(db, COLLECTION_NAME, id);
      await updateDoc(ref, { status })
        .then(() => {
          setProperties((prev) =>
            prev.map((p) => (p.id === id ? { ...p, status } : p))
          );
        })
        .catch(() => alert("Failed"));
    } catch (error) {
      alert("Failed to update status");
    }
  };

  // Get all the properties from firestore including xml files
  const getProperties = async () => {
    let ref = collection(db, COLLECTION_NAME);
    ref = query(ref, orderBy("createdAt", "desc"), limit(100));
    const res = await getDocs(ref);

    if (res.empty) {
      setProperties([]);
    } else {
      setProperties(
        res.docs.map((doc) => ({
          id: doc.id,
          addedFromXML: false,
          ...doc.data(),
        }))
      );
    }

    await loadDataFromXMLFiles();
  };

  useEffect(() => {
    if (modalData.modal == null && !modalData.addedFromXML) {
      getProperties();
    } else {
      return;
    }
  }, [modalData.modal]);
  const idColors = {
    "1": "#FFC0CB", // Light Pink
    "2": "#ADD8E6", // Light Blue
    "3": "#90EE90", // Light Green
    "4": "#FFA07A", // Light Salmon
    // Add more colors as needed
  };
  const loadDataFromXMLFiles = async () => {
    const results = await readXMLFiles();
    if (results.length > 0) {
      if (properties && properties.length > 0) {
        setProperties((prev) => [...prev, ...results]);
      } else {
        setProperties([...results]);
      }
    }
  };

  useEffect(() => {
    setTitle("All properties");
    getProperties();
  }, []);

  return properties ? (
    <div className="h-screen py-4 xsm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 flex flex-col gap-10 duration-300">
      {/* Absolute modal box */}
      {modalData.modal != null && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          className="fixed z-999 w-screen h-screen inset-0 bg-black/5 p-2 flex items-center justify-center duration-300"
        >
          {/* Absolute box */}
          <div
            className="absolute inset-0 z-10"
            onClick={() => {
              setModalData({
                modal: null,
                data: [],
              });
            }}
          ></div>

          {modalData.modal === "FILES" ? (
            <Files
              id={modalData.id}
              files={modalData.data}
              addedFromXML={modalData.addedFromXML}
              closeModal={() => {
                setModalData({ modal: null, data: [] });
              }}
            />
          ) : modalData.modal === "IMAGES" ? (
            <Images
              id={modalData.id}
              images={modalData.data}
              addedFromXML={modalData.addedFromXML}
              closeModal={() => {
                setModalData({ modal: null, data: [] });
              }}
            />
          ) : null}
        </motion.div>
      )}

      {/* Absolute button to delete selected */}
      {selectedItems.length > 0 && (
        <div className="absolute bottom-4 z-50">
          <div
            role="button"
            onClick={deleteSelected}
            className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-rose-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
          >
            <span>Delete selected</span>
            <Icon
              icon="solar:trash-bin-minimalistic-bold-duotone"
              fontSize={16}
            />
          </div>
        </div>
      )}

      {/* Top App Bar */}
      <div className="w-full flex items-center justify-between gap-10">
        <div className="flex flex-col items-start">
          <h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
            Properties Listings
          </h1>

          {properties.length > 0 && (
            <div className="text-sm tracking-tight text-gray-600">
              {" "}
              Total{" "}
              <span className="font-medium text-gray-800">
                {properties.length}
              </span>{" "}
              properties found.{" "}
            </div>
          )}
        </div>

        <div className="flex items-center gap-6">
          <a
            href={pathname + "/add"}
            className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-blue-400 to-indigo-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
          >
            <span>Add new property</span>
            <Icon icon="solar:add-square-bold" fontSize={16} />
          </a>
        </div>
      </div>

      {/* Table | Properties */}
      {properties.length > 0 ? (
 <div className="w-full h-auto overflow-auto">
 <table
   className="w-full h-full min-w-max overflow-auto border-collapse"
   id="datasTable"
 >
   <thead className="w-full text-sm tracking-tighter border-b">
     <tr className="text-left *:px-4">
       <th></th>
       <th className="py-2">SN</th>
       <th>Property Title</th>
       <th>Address</th>
       <th>Price</th>
       <th>Property Type</th>
       <th>Property Status</th>
       <th>Added At</th>
       <th>Status</th>
       <th>Actions</th>
     </tr>
   </thead>

   <tbody>
     {properties
       .slice() // Create a copy of the properties array
       .sort((a, b) => {
         if (a.id === b.id) {
           // If IDs are the same, sort by createdAt in descending order
           return b.createdAt.seconds - a.createdAt.seconds;
         }
         // Otherwise, sort by ID (you can change to ascending or descending as needed)
         return a.id.localeCompare(b.id); // Assuming id is a string
       })
       .map((property, index) => (
         <tr
           key={index}
           className="text-left text-xsm hover:bg-gray-100 even:bg-gray-50 *:px-4"
           
         >
           {property.addedFromXML ? (
             <td>
               <input
                 type="checkbox"
                 value={property.id}
                 id={"messageSelected" + index}
                 name={"property" + index}
                 onChange={handleCheckboxChange}
                 checked={selectedItems.includes(property.id)}
               />
             </td>
           ) : (
             <td></td>
           )}

           <td align="middle" className="py-6">
             <span className="font-medium text-xs">{index + 1}.</span>
           </td>

           <td className="font-semibold">
             <a
               href={`${pathname}/${property.id}`}
               className="max-w-[350px] line-clamp-1 text-ellipsis"
             >
               {property.title ?? ""}
             </a>
           </td>

           <td className="font-medium">
             <div className="max-w-60 line-clamp-1 text-ellipsis">
               {property.loc.name}, {property.loc.city ?? ""}
             </div>
           </td>

           <td className="font-semibold">{property.price ?? "..."}</td>
           <td className="font-semibold">{property.ptype ?? "..."}</td>
           <td className="font-semibold">{property.pstatus ?? "..."}</td>

           <td>
             <div className="min-w-max font-medium">
               {moment(property.createdAt.seconds * 1000).format(
                 "MMM DD, hh:mm A"
               )}
             </div>
           </td>
           <td>
             <select
               id={"status" + index}
               required
               className="px-4 py-1 border border-blue-500 outline-none rounded-sm bg-transparent font-semibold text-sm"
               defaultValue={property.status ?? ""}
               onChange={(e) => {
                 const val = e.currentTarget.value;
                 toggleStatus(property.id, val);
               }}
             >
               {property.addedFromXML ? (
                 <option value="" key="0">
                   Active
                 </option>
               ) : (
                 statusTypes.map((s, i) => {
                   let option = s.replace("_", " ").toLowerCase();
                   option =
                     option.charAt(0).toUpperCase() +
                     option.slice(1, option.length);
                   return (
                     <option value={s} key={i}>
                       {option}
                     </option>
                   );
                 })
               )}
             </select>
           </td>
           {property.addedFromXML ? (
             !["d", "d"].includes(process.env.NODE_ENV) ? (
               <td>
                 <div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
                   <div
                     role="button"
                     onClick={() => {
                       const confirmed = window.confirm(
                         "Are you sure you want to delete this property permanently."
                       );

                       if (confirmed) {
                         axios
                           .post(
                             "/backend/deleteFile.php",
                             {
                               filepath: property.filepath,
                             },
                             {
                               headers: {
                                 "Content-Type":
                                   "application/x-www-form-urlencoded",
                               },
                             }
                           )
                           .then((res) => {
                             if (res.data.deleted) {
                               alert("XML file deleted successfully.");
                               window.location.reload();
                             } else {
                               alert("Failed to delete.");
                             }
                           })
                           .catch(() => alert("Failed to delete."));
                       }
                     }}
                     className="hover:text-red-600 cursor-pointer"
                     title="Delete property"
                   >
                     <Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
                   </div>
                 </div>
               </td>
             ) : null
           ) : (
             <td>
               <div className="flex items-center gap-4 text-gray-800 *:duration-300 *:text-2xl">
                 <a
                   href={`${pathname}/${property.id}/edit`}
                   className="hover:text-blue-600"
                   title="Edit property"
                 >
                   <Icon icon="solar:pen-new-round-bold-duotone" />
                 </a>

                 <div
                   role="button"
                   onClick={() => {
                     const confirmed = window.confirm(
                       "Are you sure you want to delete this property permanently."
                     );

                     if (confirmed) {
                       deleteProperty(property)
                         .then(() => {
                           window.location.reload();
                         })
                         .catch(() => alert("Failed to delete."));
                     }
                   }}
                   className="hover:text-red-600 cursor-pointer"
                   title="Delete property"
                 >
                   <Icon icon="solar:trash-bin-minimalistic-bold-duotone" />
                 </div>
               </div>
             </td>
           )}
         </tr>
       ))}
   </tbody>
 </table>
</div>

      ) : (
        <div className="flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
          <div className="grid text-center justify-items-center gap-4">
            <div className="text-8xl text-red-500">
              <Icon icon="solar:buildings-3-bold-duotone" />
            </div>

            <div className="font-medium text-lg tracking-tighter">
              <span>Couldn't found any properties.</span>
            </div>
          </div>
        </div>
      )}

      {/* Grids | Properties | Contents */}
      {/* <div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto">
        {properties.map((property, index) => (
          <Property key={index} property={property} prefix={pathname} delay={0.1 * index} />
        ))}
      </div> */}
    </div>
  ) : (
    <div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
      <div className="grid text-center justify-items-center gap-4">
        <div className="text-6xl text-indigo-500 animate-spin">
          <Icon icon="solar:black-hole-line-duotone" />
        </div>

        <h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
          Loading properties...
        </h1>
      </div>
    </div>
  );
}

export {
  AddProperty,
  ViewProperty,
  EditProperty,
  propertyStatus,
  propertyTypes,
  COLLECTION_NAME,
};

