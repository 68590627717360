import { deleteDoc, doc } from "firebase/firestore";
import db from "../../../firebase-config";
import { deleteFiles, updateCount } from "../utils";
import axios from "axios";
import moment from "moment";

export function deleteProperty(property) {
  return new Promise(async (resolve, reject) => {
    await deleteFiles([...property.images, ...property.files]);
    const newsDoc = doc(db, "properties", property.id);
    await deleteDoc(newsDoc)
      .then(async () => {
        await updateCount("properties");
        resolve(true);
      })
      .catch(() => reject(false));
  });
}

const xmlTags = {
  id: "uniqueID",
  subTitle: "",
  beds: "features bedrooms",
  tubs: "features bathrooms",
  parkings: "features garages",
  land: "landCategory",
  ptype: "category",
  agents: "listingAgent[id]",
  price: "price",
  priceText: "priceView",
  underOffer:'underOffer',
  rental_per_week: "rent[period='weekly']",
  rental_monthly: "rent[period='monthly']",

  security_bond: "bond",
  files: "media attachment",
  images: "objects img",
  links: "externalLink",
  inspections: "inspectionTimes inspection",
  area: "landDetails area",
  desc: "description",
  title: "headline",
  loc: {
    streetNumber: "address streetNumber",
    name: "address street",
    city: "address suburb",
    url: "",
    lat: "",
    long: "",
  },
  createdAt: "propertyList",
};


const getXMLContent = (doc, key, attr = null) => {
  const el = doc.querySelector(`${key}`);
  if (el) {
    if (attr != null) {
      if (el.hasAttribute(attr)) {
        return el.getAttribute(attr);
      } else {
        return "";
      }
    } else {
      return el.textContent;
    }
  } else {
    return "";
  }
};


function parseXmlDocument(doc) {
  return new Promise(async (resolve, reject) => {
    try {
      const getContent = (key, attr = null) => {
        return getXMLContent(doc, key, attr);
      };

      const getAgents = () => {
        const agents = [];
        doc.querySelectorAll(`${xmlTags.agents}`).forEach((el) => {
          if (el.querySelector("name")) {
            agents.push({
              name: el.querySelector("name").textContent,
              email: el.querySelector("email").textContent,
              phone: el.querySelector('telephone[type="mobile"]').textContent,
              image: "https://i2.au.reastatic.net/150x200-format=avif/d24be302be3e5bbb2c85c076817f62546fe2819a9114af30689cac59555b7905/main.jpg",
            });
          }
        });
        return agents;
      };
      

      // Check whether to display the price or text
      const priceOrText = () => {
        let price =
          getContent(xmlTags.priceText) > 0
            ? getContent(xmlTags.priceText)
            : null;
        const priceDisplay = getContent(xmlTags.price, "display");
        if (priceDisplay.length > 0 && priceDisplay === "yes") {
          price =
            getContent(xmlTags.price) > 0
              ? "$ " + getContent(xmlTags.price)
              : null;
        }

        return price;
      };

      const formatInspectionTime = (str) => {
        // Regular expression patterns to match date, start time, and end time
        const datePattern = /^\d{1,2}-[A-Za-z]{3}-\d{4}/;
        const timePattern = /\b\d{1,2}:\d{2}[ap]m\b/g;
      
        // Extracting date
        const dateMatch = str.match(datePattern);
        const date = dateMatch ? dateMatch[0] : "";
      
        // Extracting start time and end time
        const times = str.match(timePattern);
        const startTime = times ? times[0] : "";
        const endTime = times ? times[1] : "";
      
        // Convert time to 24-hour format
        const convertTo24HourFormat = (time) => {
          const [hours, minutes] = time.slice(0, -2).split(':');
          const period = time.slice(-2);
          let hours24 = parseInt(hours, 10);
          if (period.toLowerCase() === 'pm' && hours24 !== 12) {
            hours24 += 12;
          } else if (period.toLowerCase() === 'am' && hours24 === 12) {
            hours24 = 0;
          }
          return `${String(hours24).padStart(2, '0')}:${minutes}`;
        };
      
        const startTime24 = startTime ? convertTo24HourFormat(startTime) : "";
        const endTime24 = endTime ? convertTo24HourFormat(endTime) : "";
      
        return { date, start_time: startTime24, end_time: endTime24 };
      };
      
      const isLand = doc.querySelector(`propertyList land`);
      const isSale = doc.querySelector(`propertyList residential[status="current"]`);
      const isLeased = doc.querySelector(`propertyList [status="leased"]`);
      const isSold = doc.querySelector(`propertyList [status="sold"]`);

      const isRental = isLeased
        ? false
        : doc.querySelector(`propertyList rental`);

      const result = {

        id: getContent(xmlTags.id),
        underOffer: getContent(xmlTags.underOffer, 'value'), // Pass 'value' attribute here

        addedFromXML: true,
        land: isLand ? true : false,
        rental: isRental ? true : false,
        sale: isSale ? true : false,
        leased: isLeased ? true : false,
        status: "ACTIVE",
        sold: isSold?true:false,
        title: getContent(xmlTags.title),
        priceText: getContent(xmlTags.priceText),

        price: priceOrText(),
        area:
          getContent(xmlTags.area).length > 0
            ? getContent(xmlTags.area) + " M2"
            : null,
        ptype: isLand
          ? "Land"
          : getContent(xmlTags.ptype, "name"),
        pstatus:
          isLand || isSale
            ? "For sale"
            : isRental
            ? "For rent"
            : isLeased
            ? "Leased"
            : isSold
            ? "Sold":"",
        beds: parseInt(getContent(xmlTags.beds)),
        tubs: parseInt(getContent(xmlTags.tubs)),
        parkings: parseInt(getContent(xmlTags.parkings)),
        desc: getContent(xmlTags.desc),
        images: Array.from(
          doc.querySelectorAll(`${xmlTags.images}[id][url][format]`)
        ).map((el) => ({
          url: el.getAttribute("url"),
        })),
        files: Array.from(
          doc.querySelectorAll(`${xmlTags.files}[id][url][contentType]`)
        ).map((el, i) => ({
          url: el.getAttribute("url"),
          name: "Attachment File " + i + 1,
        })),
        inspections: Array.from(
          doc.querySelectorAll(`${xmlTags.inspections}`)
        ).map((el) => formatInspectionTime(el.textContent)),
        agents: getAgents(),
        links: [
          ...Array.from(doc.querySelectorAll(`${xmlTags.links}[href]`)).map(
            (el, i) => ({
              url: el.getAttribute("href"),
              title: null,
            })
          ),
          {
            url: getContent("videoLink", "href"),
            title: null,
          },
        ],
        loc: {
          name:
            getContent(xmlTags.loc.streetNumber) +
            " " +
            getContent(xmlTags.loc.name),
          city: getContent(xmlTags.loc.city),
          url: "",
          lat: "",
          long: "",
        },
        createdAt: {
          seconds: moment(
            getContent(xmlTags.createdAt, "date"),
            "YYYY-MM-DD-HH:mm:ss"
          ).format("X"),
        },

      };

      if (isRental) {
        result.security_bond = getContent(xmlTags.security_bond);
        result.rental_per_week = getContent(xmlTags.rental_per_week);
        result.rental_monthly = getContent(xmlTags.rental_monthly);

      }

      resolve(result);
    } catch (error) {
      resolve({});
    }
  });
}

export async function readXMLFiles(file = null) {
  try {
   const PRODUCTION = process.env.NODE_ENV === "production";
//   const PRODUCTION = window.location.protocol === "https:";

    // let xmlFilepaths = (await axios.get("/xmls")).data;
    // if (PRODUCTION) {
    //   xmlFilepaths = (await axios.get("/backend/readXMLFiles.php")).data;
    // }

    const xmlFilepaths = (await axios.get("/backend/readXMLFiles.php")).data;

    // Checking if the file is provided or not and if it is available in the file paths or not.
    // if (file != null && xmlFilepaths.some((p) => p === file)) {
    //  return new Promise(async (resolve, reject) => {
    //    try {
    //      const response = await axios.get("/eagle/" + file, {
    //        "Content-Type": "application/xml; charset=utf-8",
    //      });
    //      var xml = response.data;
    //      const parser = new DOMParser();
    //      const xmlDoc = parser.parseFromString(xml, "text/xml");
    //      const data = await parseXmlDocument(xmlDoc);
    //      resolve({ ...data, xmlFile: file });
    //    } catch (error) {
    //      resolve({});
    //    }
    //  });
    // } else {

    return Promise.all(
      xmlFilepaths.map(async (file, i) => {
        // var xml = "";
        // if (!PRODUCTION) {
        //   const xmlFilePath = "/xmls/" + file;
        //   const response = await axios.get(xmlFilePath, {
        //     "Content-Type": "application/xml; charset=utf-8",
        //   });
        //   xml = response.data;
        // } else {
        //   xml = file.content;
        // }
       var xml = file.content;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, "text/xml");
        const data = await parseXmlDocument(xmlDoc);
        return { ...data, filepath: file.filepath };
      })
    );
    // }
  } catch (error) {
    return [];
  }
}

