import { Icon } from "@iconify/react";
import React from "react";
import NavItem from "./navItem";

const PREFIX = "/admin-dashboard";

export default function SideBar({ user, logOut }) {
	return (
		<div className="relative h-full grid items-end">
			<div className="h-full overflow-auto">
				<div className="space-y-6">
					{/* Top App Logo */}
					<div className="p-4 flex items-center justify-center">
						<div className="w-20 h-auto flex items-center justify-center">
							<img
								src={process.env.REACT_APP_APP_LOGO}
								alt={process.env.REACT_APP_APP_NAME ?? "logo"}
								className="w-auto h-auto object-contain"
							/>
						</div>
					</div>

					<div className="space-y-6 pb-4 tracking-tighter">
						<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
							<NavItem to="/admin-dashboard" title="Dashboard">
								<Icon icon="solar:home-2-bold-duotone" fontSize={18} />
							</NavItem>
							<NavItem to={PREFIX + "/messages"} title="Messages">
								<Icon icon="solar:chat-round-dots-bold" fontSize={18} />
							</NavItem>
							<NavItem to={PREFIX + "/sell-property-requests"} title="Sell Property Requests">
								<Icon icon="solar:filters-bold-duotone" fontSize={18} />
							</NavItem>
						</div>

						{/* Manage Property */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Manage Property</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/all-properties"} title="All properties">
									<Icon icon="solar:buildings-3-bold-duotone" fontSize={18} />
								</NavItem>

								<NavItem to={PREFIX + "/all-properties/add"} title="Add property">
									<Icon icon="solar:add-square-bold-duotone" fontSize={18} />
								</NavItem>
							</div>
						</div>

						{/* Meet Our Team */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Meet Our Team</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/our-teams"} title="Our teams">
									<Icon icon="solar:users-group-rounded-bold-duotone" fontSize={18} />
								</NavItem>

								<NavItem to={PREFIX + "/our-teams/add"} title="Add new member">
									<Icon icon="solar:add-square-bold-duotone" fontSize={18} />
								</NavItem>
							</div>
						</div>

						{/* Manage News */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Manage News</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/all-news"} title="All news">
									<Icon icon="solar:notebook-bold-duotone" fontSize={18} />
								</NavItem>

								<NavItem to={PREFIX + "/all-news/add"} title="Add news">
									<Icon icon="solar:add-square-bold-duotone" fontSize={18} />
								</NavItem>
							</div>
						</div>

						{/* Reviews */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Reviews</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/all-reviews"} title="All Reviews">
									<Icon icon="solar:archive-minimalistic-bold-duotone" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/all-reviews/add"} title="Add Review">
									<Icon icon="solar:archive-up-minimlistic-bold-duotone" fontSize={18} />
								</NavItem>
							</div>
						</div>

						{/* Appraisals */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Appraisals Requests</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/free-appraisals"} title="Free Appraisals">
									<Icon icon="solar:bag-2-bold" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/rental-appraisals"} title="Rental Appraisals">
									<Icon icon="solar:home-bold-duotone" fontSize={18} />
								</NavItem>
							</div>
						</div>

						{/* User */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Admin Users</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/users"} title="Users">
									<Icon icon="solar:shield-user-bold-duotone" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/users/add"} title="Add User">
									<Icon icon="solar:user-plus-rounded-bold-duotone" fontSize={18} />
								</NavItem>
							</div>
						</div>

						{/* Other */}
						<div className="grid gap-2 justify-items-start">
							<div className="font-semibold text-base px-6">
								<span>Other</span>
							</div>

							<div className="w-full grid *:h-10 *:border-l-4 *:duration-300 *:px-4 *:flex *:items-center *:justify-between *:gap-4">
								<NavItem to={PREFIX + "/contact-us"} title="Contact details">
									<Icon icon="solar:smartphone-bold-duotone" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/about-us"} title="About us">
									<Icon icon="solar:people-nearby-bold-duotone" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/terms-and-conditions"} title="Terms & Conditions">
									<Icon icon="solar:notes-minimalistic-bold-duotone" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/privacy-policy"} title="Privacy Policy">
									<Icon icon="solar:check-circle-bold" fontSize={18} />
								</NavItem>
								<NavItem to={PREFIX + "/external-links"} title="External Links">
									<Icon icon="solar:link-round-angle-linear" fontSize={18} />
								</NavItem>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full min-h-max border-t border-gray-100 grid gap-4 p-4 tracking-tighter">
				<div className="grid justify-items-start *:line-clamp-1 *:text-ellipsis">
					<div className="font-semibold text-base">
						<span>{user.username ?? ""}</span>
					</div>

					<div className="relative -top-1 font-medium text-sm text-gray-600">
						<span>{user.email ?? ""}</span>
					</div>
				</div>

				<button
					type="button"
					className="rounded-sm w-full outline-none border-none ring-0 flex items-center justify-center gap-2 px-4 h-10 font-medium bg-gradient-to-br from-orange-500 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					onClick={logOut}
				>
					<span>Log out</span>
					<Icon icon="solar:power-line-duotone" fontSize={16} />
				</button>
			</div>
		</div>
	);
}
