import React from "react";
import { motion } from "framer-motion";

export default function ContactUs() {
	return (
		<motion.div
			initial={{ opacity: 0, y: 6 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3 }}
		>
			<div className="bg-gradient-to-br from-orange-600 to-yellow-500 text-white flex items-center justify-center duration-300 p-20">
				<h1 className="font-semibold text-4xl">Contact Us</h1>
			</div>
		</motion.div>
	);
}
