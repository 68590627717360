import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Icon } from "@iconify/react";
import { IoStar } from "react-icons/io5";
import { useContainerContext } from "../../contexts/container";
import { collection, limit, orderBy, query, getDocs, getDoc } from "firebase/firestore";
import db from "../../firebase-config";

export default function Testimonials() {
	const { setTitle } = useContainerContext();
	const [testimonials, setTestimonials] = useState([]);

	const [loaded, setLoaded] = useState(false);

	const getReviews = async () => {
		const ref = collection(db, "reviews");
		const q = query(ref, orderBy("createdAt", "desc"), limit(50));
		const res = await getDocs(q);

		if (res.empty) {
			setTestimonials([]);
		} else {
			res.docs.map(async (d) => {
				const data = d.data();
				const agent = { ...d.data(), id: d.id };
				console.log("start");
				await getDoc(data.agent).then((res) => {
					if (res.exists()) {
						setTestimonials((prev) => [...prev, { ...agent, agent: { ...res.data() } }]);
					} else {
						setTestimonials((prev) => [...prev, { ...agent, agent: {} }]);
					}
				});
			});
		}
	};

	useEffect(() => {
		if (loaded) {
			getReviews();
		} else return;
	}, [loaded]);

	useEffect(() => {
		setTitle("Testimonials");
		setLoaded(true);

		return () => {
			setLoaded(false);
		};
	}, []);

	return (
		<motion.div initial={{ opacity: 0, y: 6 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
			<div className="bg-gradient-to-br from-orange-600 to-yellow-500 text-white flex items-center justify-center duration-300 p-20">
				<h1 className="font-semibold text-4xl">Testimonials</h1>
			</div>

			<div className="w-full px-2 duration-300">
				<div className="w-full md:w-11/12 xl:w-10/12 py-10 mx-auto overflow-auto duration-300">
					{/* Grids | Land | Contents */}
					{testimonials ? (
						testimonials.length > 0 ? (
							<div className="flex flex-col justify-start bg-gray-50 border divide-y">
								{testimonials.map((_, index) => (
									<div
										key={index}
										className="p-4 bg-white hover:bg-gray-50 flex flex-col mxs:flex-row justify-start items-stretch gap-4 mxs:gap-10 duration-300"
									>
										<div className="w-full mxs:w-4/12 xsm:w-3/12 md:w-2/12 rounded-sm border border-gray-100 overflow-hidden duration-300">
											<img
												src={_.image.url ?? "/static/images/placeholder.png"}
												alt=""
												className="w-full h-full object-cover"
											/>
										</div>

										<div className="w-full mxs:w-8/12 xsm:w-9/12 md:w-10/12 grid gap-5 items-start justify-items-start duration-300">
											<div className="grid justify-items-start text-left">
												<div className="text-lg font-bold line-clamp-2 text-ellipsis tracking-tight text-pretty group-hover/review:text-indigo-600 duration-200">
													{_.username ?? "Unknown"}
												</div>

												<div className="mt-2 flex items-center gap-2 text-sm text-indigo-600">
													{Array.from({ length: _.stars }, () => Array("1").fill("1")).map(
														(s, i) => (
															<IoStar key={i} />
														)
													)}
												</div>
											</div>

											<div className="line-clamp-4 text-ellipsis text-pretty text-left tracking-tight text-sm text-gray-700">
												{_.desc ?? ""}
											</div>
										</div>
									</div>
								))}
							</div>
						) : (
							<div className="w-full p-20 rounded-xl bg-red-50 flex items-center justify-center">
								<div className="grid text-center justify-items-center gap-4">
									<div className="text-8xl text-red-500">
										<Icon icon="solar:notes-bold-duotone" />
									</div>

									<div className="font-medium text-lg tracking-tighter">
										<span>Couldn't found any testimonials.</span>
									</div>
								</div>
							</div>
						)
					) : (
						<div className="w-full p-20 bg-orange-50 rounded-xl flex items-center justify-center">
							<div className="grid text-center justify-items-center gap-4">
								<div className="text-6xl text-indigo-500 animate-spin">
									<Icon icon="solar:black-hole-line-duotone" />
								</div>

								<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
									Loading testimonials...
								</h1>
							</div>
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
}
