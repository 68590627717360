import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDashboardContext } from "../../dashboardContext";
import { Icon } from "@iconify/react";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import db from "../../../firebase-config";
import Team from "./team";
import AddMember from "./add";
import ViewMember from "./view";
import EditMember from "./edit";

export default function OurTeams() {
	const { setTitle } = useDashboardContext();
	const { pathname } = useLocation();

	const [teams, setTeams] = useState(null);

	const getTeams = async () => {
		const ref = collection(db, "teams");
		const q = query(ref, orderBy("createdAt", "desc"), limit(50));
		const res = await getDocs(q);

		if (res.empty) {
			setTeams([]);
		} else {
			setTeams(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
		}
	};

	useEffect(() => {
		setTitle("Our Teams");
		getTeams();
	}, []);

	return teams ? (
		<div className="h-screen py-4 xsm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 flex flex-col duration-300">
			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						Our Team Members
					</h1>

					{teams.length > 0 && (
						<div className="text-sm tracking-tight text-gray-600">
							Total <span className="font-medium text-gray-800">{teams.length}</span> members found.
						</div>
					)}
				</div>

				<div className="flex items-center gap-6">
					<a
						href={pathname + "/add"}
						className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-blue-400 to-indigo-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
					>
						<span>Add new member</span>
						<Icon icon="solar:add-square-bold" fontSize={16} />
					</a>
				</div>
			</div>

			{teams.length > 0 ? (
				<Fragment>
					{/* Grids | Teams | Contents */}
					<div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto">
						{teams.map((team, index) => (
							<Team key={index} team={team} prefix={pathname} delay={0.1 * index} />
						))}
					</div>
				</Fragment>
			) : (
				<div className="flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:users-group-rounded-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>Teams members aren't added.</span>
						</div>
					</div>
				</div>
			)}
		</div>
	) : (
		<div className="w-full h-full p-20 rounded-xl flex items-center justify-center">
			<div className="grid text-center justify-items-center gap-4">
				<div className="text-6xl text-indigo-500 animate-spin">
					<Icon icon="solar:black-hole-line-duotone" />
				</div>

				<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
					Loading Our Team Members...
				</h1>
			</div>
		</div>
	);
}

export { AddMember, ViewMember, EditMember };
