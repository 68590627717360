import React from "react";
import { motion } from "framer-motion";
import FormInput from "./FormInput";
import { Icon } from "@iconify/react";

export default function Links({ links = [], setLinks }) {
	const removeBox = (key) => {
		const boxToRemove = document.getElementById(`#${key}`);
		if (boxToRemove) {
			boxToRemove.remove();
		}
		let __links = [...links];
		__links = __links.filter((l) => l.key !== key);
		setLinks([...__links]);
	};

	return (
		<div className="flex flex-col items-start gap-4">
			<h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
				Links
			</h1>

			{links.length > 0 && (
				<div className="w-full flex flex-col items-start gap-4" id="inspectionTimeContainer">
					{links.map((link, index) => (
						<motion.div
							key={index}
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.3 }}
							id={link.key}
							className="w-full flex items-center justify-between gap-8 border-blue-500 duration-300 rounded-sm"
						>
							<div className="w-full flex-grow">
								<div className="w-full grid justify-items-start">
									<motion.input
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeInOut" }}
										className="w-full py-2 font-medium text-sm outline-none border-none placeholder:text-gray-500 placeholder:font-normal"
										required
										autoComplete="off"
										placeholder="👉 Title of url"
										value={link?.title ?? ""}
										onChange={(e) => {
											const val = e.currentTarget.value;
											links[index].title = val;
											setLinks([...links]);
										}}
									/>

									<motion.div
										initial={{ opacity: 0, y: -20 }}
										animate={{ opacity: 1, y: 0 }}
										transition={{ duration: 0.3, ease: "easeOut" }}
										className="w-full h-11"
									>
										<FormInput
											type="url"
											id="url"
											required
											autoComplete="off"
											placeholder="Type your link url..."
											value={link?.url ?? ""}
											onChange={(e) => {
												const val = e.currentTarget.value;
												links[index].url = val;
												setLinks([...links]);
											}}
										/>
									</motion.div>
								</div>
							</div>

							<div
								role="button"
								title="Remove"
								onClick={() => removeBox(link.key)}
								className="w-auto rounded-sm flex items-center justify-center place-self-end gap-2 px-10 h-11 bg-gradient-to-br from-red-400 to-red-600 hover:bg-gradient-to-tr text-xs text-white cursor-pointer duration-300"
							>
								<span>Remove</span>
							</div>
						</motion.div>
					))}
				</div>
			)}

			{/* Upload files button */}
			<div
				role="button"
				onClick={() => {
					const date = new Date();
					const key = date.getTime();
					setLinks((prev) => [...prev, { title: "", url: "", key: "box_" + key }]);
				}}
				className="rounded-sm flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-tr text-xs text-white cursor-pointer duration-300"
			>
				<span>Add</span>
				<Icon icon="solar:add-circle-outline" fontSize={16} />
			</div>
		</div>
	);
}
