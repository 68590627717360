import db from "../firebase-config";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { readXMLFiles } from "../admin/pages/properties/actions";

async function fetchProperties(
	n = 0,
	{
		sale = false,
		rental = false,
		leased = false,
		land = false,
		sold = false,
	} = {}
) {
	return new Promise(async (resolve, reject) => {
		let ref = collection(db, "properties");
		ref = query(ref, where("sold", "==", sold));
		ref = query(ref, where("leased", "==", leased));

		if (sale) {
			ref = query(ref, where("sale", "==", sale));
		} else if (rental) {
			ref = query(ref, where("rental", "==", rental));
		} else if (leased) {
			ref = query(ref, where("leased", "==", leased));
		} else if (land) {
			ref = query(ref, where("land", "==", land));
		}

				// **Added Code**: Filter out duplicates, keeping only the most recent entry for each property
				const filterRecentProperties = (properties) => {
					const propertyMap = new Map();
		
					properties.forEach((property) => {
						const existingProperty = propertyMap.get(property.id);
						if (!existingProperty || property.createdAt.seconds > existingProperty.createdAt.seconds) {
							propertyMap.set(property.id, property);
						}
					});
		
					return Array.from(propertyMap.values());
				};

				
		ref = query(ref, limit(n));
		await getDocs(ref)
			.then(async (res) => {
				let results = [];
				if (!res.empty) {
					let sorted = res.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
					results = sorted.sort(
						(a, b) => b.createdAt.seconds - a.createdAt.seconds
					);
					results = filterRecentProperties(results);

				}

				await readXMLFiles()
					.then((xmlResults) => {
						xmlResults = filterRecentProperties(xmlResults);

						let filteredResults = [];
						if (sale) {
							filteredResults = xmlResults.filter((p) => p.sale === true);
						} else if (sold) {
							filteredResults = xmlResults.filter((p) => p.sold === true);
						} else if (rental) {
							filteredResults = xmlResults.filter((p) => p.rental === true);
						} else if (leased) {
							filteredResults = xmlResults.filter((p) => p.leased === true);
						} else if (land) {
							filteredResults = xmlResults.filter((p) => p.land === true);
						} else {
							if (!leased) {
								filteredResults = xmlResults.filter((p) => p.leased !== true && p.sold !== true);
							} else {
								filteredResults = xmlResults;
							}
						}
						let combinedResults = [...results, ...filteredResults];

		// End of Added Code

		resolve(combinedResults);
				
					})
					.catch((error) => {
						console.error(error);
						resolve([...results]);
					});
			})
			.catch((error) => {
				reject(error);
			});
	});
}

export { fetchProperties };
