import React, { useState } from "react";
import { motion } from "framer-motion";
import moment from "moment";
import { deleteNews } from "./actions";

export default function NewsItem({ news, index, pathname }) {
	const [status, setStatus] = useState(false);

	return (
		<motion.div
			initial={{ opacity: 0, y: -20 }}
			animate={{ opacity: 1, y: 0 }}
			transition={{ duration: 0.3, delay: 0.03 * index }}
			className="group/item"
		>
			<div className="w-full max-w-[1366px] min-w-[215px] rounded-sm bg-orange-50 bg-opacity-30 hover:bg-opacity-50 border hover:border-orange-200 flex flex-col sm:flex-row items-stretch divide-x overflow-hidden duration-300">
				<div className="relative w-full sm:w-4/12 mlg:w-2/12 flex items-center justify-center overflow-hidden duration-300">
					<a href={pathname + "/" + news.id} className="w-full h-full">
						<img
							src={news.image.url ?? "/static/images/placeholder.jpg"}
							alt={news.title ?? ""}
							className="w-full h-full object-cover group-hover/item:scale-105 bg-gray-50 overflow-hidden duration-300"
						/>
					</a>
				</div>

				<div className="w-full sm:w-8/12 mlg:w-10/12 h-full duration-300">
					<div className="flex flex-col items-start gap-6 px-2 xs:px-4 py-2 tracking-tighter duration-300">
						<div className="w-full flex flex-col lxs:flex-row items-start lxs:items-center lxs:justify-between gap-2 lxs:gap-10 duration-300">
							<a
								href={pathname + "/" + news.id}
								className="text-base slg:text-lg font-semibold line-clamp-2 text-ellipsis group-hover/item:text-orange-600 duration-300"
							>
								<span>{news?.title ?? "Unknown"}</span>
							</a>

							<div
								className={
									"relative min-w-7 w-7 h-4 cursor-pointer rounded-full duration-300 flex items-center " +
									(status ? "bg-blue-600" : "bg-gray-800")
								}
								onClick={() => setStatus(!status)}
								title={status ? "Hide this news" : "Make this news visible"}
							>
								<div
									className={
										"absolute w-3 h-3 rounded-full bg-white duration-300 " +
										(status ? "left-[45%]" : "left-[10%]")
									}
								></div>
							</div>
						</div>

						<div className="w-full flex flex-col gap-4">
							<div
								className="w-full text-sm line-clamp-4 text-ellipsis text-pretty"
								dangerouslySetInnerHTML={{ __html: news?.content ?? "" }}
							></div>

							<div className="w-full sm:w-auto flex items-center justify-between flex-wrap gap-2">
								<div className="inline-flex items-center gap-2 font-semibold">
									<span className="text-gray-800 text-sm">Added on: </span>
									<div className="text-sm text-black">
										{moment(news.createdAt.seconds * 1000).format("MMM DD, hh:mm A")}
									</div>
								</div>

								<div className="w-full sm:w-auto flex items-center gap-2 sm:gap-4 duration-300">
									<div
										role="button"
										onClick={() => {
											const confirmed = window.confirm(
												"Are you sure you want to delete this news permanently."
											);

											if (confirmed) {
												deleteNews(news)
													.then(() => {
														window.location.reload();
													})
													.catch(() => alert("Failed to remove member"));
											}
										}}
										className="bg-gradient-to-br from-red-600 to-rose-500 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-4 h-8 flex items-center justify-center rounded border duration-300"
									>
										<span>Delete</span>
									</div>

									<a
										href={pathname + "/" + news.id + "/edit"}
										className="bg-gradient-to-br from-blue-600 to-indigo-400 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-4 h-8 flex items-center justify-center rounded border duration-300"
									>
										<span>Edit</span>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}
