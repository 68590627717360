import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useContainerContext } from "../../../contexts/container";
import { Icon } from "@iconify/react";
import Property from "../utils/property";
import { fetchProperties } from "../../lib";

export default function LeasedProperties() {
	const { setTitle } = useContainerContext();

	const [properties, setProperties] = useState(null);

	const getProperties = async () => {
		await fetchProperties(8, { leased: true })
			.then((res) => {
				setProperties([...res]);
			})
			.catch((error) => {
				setProperties([]);
			});
	};

	useEffect(() => {
		setTitle("Properties for Sale");
		getProperties();
	}, []);

	return (
		<motion.div initial={{ opacity: 0, y: 6 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
			<div className="bg-gradient-to-br from-orange-600 to-yellow-500 text-white flex items-center justify-center duration-300 p-20">
				<h1 className="font-semibold text-4xl">Leased properties</h1>
			</div>

			<div className="w-full px-2 duration-300">
				<div className="w-full md:w-11/12 xl:w-10/12 h-[calc(100vh-48px)] py-10 mx-auto overflow-auto duration-300">
					{/* Grids | Properties | Contents */}
					{properties ? (
						properties.length > 0 ? (
							<div className="grid grid-cols-1 xsm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-8 row-auto">
								{properties.map((property, index) => (
									<Property key={index} property={property} pfor={0} delay={0.1 * index} />
								))}
							</div>
						) : (
							<div className="w-full p-20 rounded-xl bg-red-50 flex items-center justify-center">
								<div className="grid text-center justify-items-center gap-4">
									<div className="text-8xl text-red-500">
										<Icon icon="solar:buildings-3-bold-duotone" />
									</div>

									<div className="font-medium text-lg tracking-tighter">
										<span>Couldn't found any properties.</span>
									</div>
								</div>
							</div>
						)
					) : (
						<div className="w-full p-20 bg-orange-50 rounded-xl flex items-center justify-center">
							<div className="grid text-center justify-items-center gap-4">
								<div className="text-6xl text-indigo-500 animate-spin">
									<Icon icon="solar:black-hole-line-duotone" />
								</div>

								<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
									Loading properties...
								</h1>
							</div>
						</div>
					)}
				</div>
			</div>
		</motion.div>
	);
}
