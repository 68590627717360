import React, { useEffect, useState } from "react";
import { useDashboardContext } from "../../dashboardContext";
import { collection, getDocs, orderBy, query, limit, getDoc } from "firebase/firestore";
import db from "../../../firebase-config";
import { IoStar } from "react-icons/io5";
import { deleteReview } from "./actions";
import { Icon } from "@iconify/react";

import AddReview from "./add";
import EditReview from "./edit";

export default function Reviews() {
	const { setTitle } = useDashboardContext();

	const [loaded, setLoaded] = useState(false);

	const [reviews, setReviews] = useState([]);

	const getReviews = async () => {
		const ref = collection(db, "reviews");
		const q = query(ref, orderBy("createdAt", "desc"), limit(50));
		await getDocs(q).then((res) => {
			if (res.empty) {
				setReviews([]);
			} else {
				res.docs.map(async (d) => {
					const data = d.data();
					const agent = { ...d.data() };
					await getDoc(data.agent).then((res) => {
						if (res.exists()) {
							setReviews((p) => [...p, { ...agent, agent: { ...res.data() } }]);
						} else {
							setReviews((p) => [...p, { ...agent, agent: {} }]);
						}
					});
				});
			}
		});
	};

	useEffect(() => {
		if (loaded) {
			getReviews();
		} else return;
	}, [loaded]);

	useEffect(() => {
		setTitle("Reviews");
		setLoaded(true);

		return () => {
			setReviews([]);
			setLoaded(false);
		};
	}, []);

	return (
		<div className="h-screen py-4 xsm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 flex flex-col duration-300">
			{/* Top App Bar */}
			<div className="w-full flex items-center justify-between gap-10">
				<div className="flex flex-col items-start">
					<h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
						All Reviews
					</h1>

					{reviews.length > 0 && (
						<div className="text-sm tracking-tight text-gray-600">
							Total <span className="font-medium text-gray-800">{reviews.length}</span> reviews found.
						</div>
					)}
				</div>

				<a
					href={"/admin-dashboard/all-reviews/add"}
					className="rounded-full flex items-center justify-center gap-2 px-6 h-10 bg-gradient-to-br from-blue-400 to-indigo-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
				>
					<span>Add Review</span>
					<Icon icon="solar:add-square-bold" fontSize={16} />
				</a>
			</div>

			{reviews.length > 0 ? (
				<div className="flex flex-col justify-start bg-gray-50 border divide-y">
					{reviews.map((_, index) => (
						<div
							key={index}
							className="p-4 bg-white hover:bg-gray-50 flex flex-col mxs:flex-row justify-start items-stretch gap-4 mxs:gap-10 duration-300"
						>
							<div className="w-full mxs:w-4/12 xsm:w-3/12 md:w-2/12 rounded-sm border border-gray-100 overflow-hidden duration-300">
								<img
									src={_.image.url ?? "/static/images/placeholder.png"}
									alt=""
									className="w-full h-full object-cover"
								/>
							</div>

							<div className="w-full mxs:w-8/12 xsm:w-9/12 md:w-10/12 grid gap-5 justify-items-start duration-300">
								<div className="flex-grow grid justify-items-start text-left">
									<div className="text-lg font-bold line-clamp-2 text-ellipsis tracking-tight text-pretty group-hover/review:text-indigo-600 duration-200">
										{_.username ?? "Unknown"}
									</div>

									<div className="mt-2 flex items-center gap-2 text-sm text-indigo-600">
										{Array.from({ length: _.stars }, () => Array("1").fill("1")).map((s, i) => (
											<IoStar key={i} />
										))}
									</div>
								</div>

								<div className="line-clamp-4 text-ellipsis text-pretty text-left tracking-tight text-sm text-gray-700">
									{_.desc ?? ""}
								</div>

								<div className="flex gap-4 flex-wrap">
									<a
										href={"/admin-dashboard/all-reviews/" + _.id}
										title="Edit property"
										className="bg-gradient-to-br from-indigo-600 to-blue-500 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-4 h-8 flex items-center justify-center rounded-sm border duration-300"
									>
										<span>Edit</span>
									</a>

									<div
										role="button"
										onClick={() => {
											const confirmed = window.confirm(
												"Are you sure you want to delete this reviews permanently."
											);

											if (confirmed) {
												deleteReview(_)
													.then(() => {
														window.location.reload();
													})
													.catch(() => alert("Failed to remove member"));
											}
										}}
										className="bg-gradient-to-br from-red-600 to-rose-500 hover:bg-gradient-to-tr text-white text-sm w-1/2 sm:w-auto px-4 h-8 flex items-center justify-center rounded-sm border duration-300"
									>
										<span>Delete</span>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			) : (
				<div className="flex-grow p-20 rounded-xl bg-red-50 flex items-center justify-center">
					<div className="grid text-center justify-items-center gap-4">
						<div className="text-8xl text-red-500">
							<Icon icon="solar:archive-minimalistic-bold-duotone" />
						</div>

						<div className="font-medium text-lg tracking-tighter">
							<span>Reviews not found.</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export { AddReview, EditReview };
