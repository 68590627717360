import React from "react";

export default function GetInTouch() {
	return (
		<div className="w-full px-2 py-12 xs:py-16 sm:py-28 md:py-40 bg-indigo-50/30 duration-300">
			<div className="w-full md:w-11/12 xl:w-10/12 mx-auto flex justify-center gap-10 *:duration-300 duration-300">
				<a
					href="/"
					className="relative w-3/12 bg-slate-50 font-bold text-4xl md:text-3xl lg:text-4xl text-white text-center rounded-3xl hidden lg:block overflow-hidden"
				>
					<div
						className="relative w-full h-full p-16 sm:p-24 md:p-32 bg-cover bg-center hover:scale-105 duration-300"
						style={{
							backgroundImage: "url('/images/photo-1513584684374-8bab748fbf90.avif')",
						}}
					>
						<div className="absolute inset-0 bg-black/30 flex items-center justify-center">
							<span>Selling</span>
						</div>
					</div>
				</a>

				<div className="w-full lg:w-6/12 grid gap-20 justify-items-center max-w-2xl duration-300">
					{/* Top content */}
					<div className="grid gap-6 justify-items-center text-center">
						<h1
							className="font-extrabold text-4xl text-yellow-500 uppercase duration-300"
							style={{ wordSpacing: 10 }}
						>
							GET IN TOUCH TODAY
						</h1>

						<p className="text-gray-700 text-sm sm:text-base tracking-tight !leading-relaxed duration-300">
							To accommodate purchasers with a wide variety of preferences,
							lifestyles, and financial constraints, Krish Real Estate provides a wide
							selection of home options. There is a home for everyone, whether you
							want a small, low-maintenance property or a classic large family home.
						</p>
					</div>

					{/* Bottom boxes */}
					<div className="w-full grid grid-cols-1 xs:grid-cols-2 gap-6 sm:gap-12 *:w-full *:overflow-hidden *:bg-cover hover:*:scale-105 *:duration-300 *:bg-center *:p-16 sm:*:p-24 md:*:p-32 *:relative *:rounded-3xl *:bg-slate-50 font-bold text-4xl md:text-3xl lg:text-4xl text-white text-center duration-300">
						<a
							href="/properties-for-sale"
							style={{
								backgroundImage:
									"url('/images/photo-1512917774080-9991f1c4c750.avif')",
							}}
						>
							<div className="absolute inset-0 bg-black/30 flex items-center justify-center">
								<span>Buying</span>
							</div>
						</a>

						<a
							href="/contact-us"
							style={{
								backgroundImage:
									"url('/images/Flinders_Street_Station_Melbourne_March_2021.jpg')",
							}}
						>
							<div className="absolute inset-0 bg-black/30 flex items-center justify-center">
								<span>Get In Touch</span>
							</div>
						</a>

						<a
							href="/sell-property"
							className="lg:hidden"
							style={{
								backgroundImage:
									"url('/images/photo-1513584684374-8bab748fbf90.avif')",
							}}
						>
							<div className="absolute inset-0 bg-black/30 flex items-center justify-center">
								<span>Selling</span>
							</div>
						</a>

						<a
							href="/contact-us"
							className="lg:hidden"
							style={{
								backgroundImage:
									"url('https://plus.unsplash.com/premium_photo-1677535269540-f0829cc10196?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
							}}
						>
							<div className="absolute inset-0 bg-black/30 flex items-center justify-center">
								<span>
									Join Our <br /> Team
								</span>
							</div>
						</a>
					</div>
				</div>

				<a
					href="/contact-us"
					className="relative w-3/12 bg-slate-50 font-bold text-4xl md:text-3xl lg:text-4xl text-white text-center rounded-3xl hidden lg:block overflow-hidden"
				>
					<div
						className="relative w-full h-full p-16 sm:p-24 md:p-32 bg-cover bg-center hover:scale-105 duration-300"
						style={{
							backgroundImage:
								"url('https://plus.unsplash.com/premium_photo-1677535269540-f0829cc10196?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')",
						}}
					>
						<div className="absolute inset-0 bg-black/30 flex items-center justify-center">
							<span>
								Join Our <br /> Team
							</span>
						</div>
					</div>
				</a>
			</div>
		</div>
	);
}
