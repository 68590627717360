import { deleteDoc, doc } from "firebase/firestore";
// import { ref, deleteObject } from "firebase/storage";
import db from "../../../firebase-config";
import { deleteFiles, updateCount } from "../utils";

export function deleteTeam(team) {
	return new Promise(async (resolve, reject) => {
		await deleteFiles([{ path: team.imagePath }]);
		const docRef = doc(db, "teams", team.id);
		await deleteDoc(docRef)
			.then(async () => {
				await updateCount("teams");
				resolve(true);
			})
			.catch(() => reject(false));
		// const confirmed = window.confirm(
		// 	"Something went wrong while deleting image. Invalid file path or file not found.\nDo you want to continue deletion?"
		// );
		// if (confirmed) {
		// 	const docRef = doc(db, "teams", team.id);
		// 	await deleteDoc(docRef)
		// 		.then(async () => {
		// 			const counterRef = doc(db, "counters", "teams");
		// 			await updateDoc(counterRef, {
		// 				count: increment(-1),
		// 			});
		// 			resolve(true);
		// 		})
		// 		.catch(() => reject(false));
		// } else {
		// 	resolve(true);
		// }
	});
}
