import React, { Fragment, useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import { useDashboardContext } from "../../../dashboardContext";
import { Icon } from "@iconify/react";
import { useSearchParams } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import db from "../../../../firebase-config";
import { Location } from "../components";
import { saveFiles, updateCount } from "../../utils";
import { FiX } from "react-icons/fi";
import { COLLECTION_NAME, propertyStatus } from "..";
import {
  Area,
  Baths,
  Beds,
  Description,
  Parkings,
  Price,
  PropertyStatus,
  PropertyType,
  RentalPerWeek,
  SecurityBond,
  // Status,
  SubTitle,
  Title,
  TypeSelector,
} from "../components/FormInputs";
import Images from "../components/Images";
import Attachments from "../components/Attachments";
import InspectionTime from "../components/InspectionTime";
import Links from "../components/Links";

export default function AddProperty() {
  const { setTitle, setUpdating } = useDashboardContext();
  const [typeSelected, setTypeSelected] = useState(1);

  const [agents, setAgents] = useState([{}]);
  const [params, setParams] = useSearchParams();

  const [property, setProperty] = useState({
    title: "",
    subTitle: "",
    beds: 0,
    tubs: 0,
    parkings: 0,
    pstatus: "",
    ptype: "",
    land: false,
    rental: true,
    sale: false,
    leased: false,
    agents: "",
    area: "",
    price: "",
    sold: false,
    featured: false,
    rental_per_week: null,
    security_bond: null,
    // status: "ACTIVE",
    liked: false,
  });

  const [loc, setLoc] = useState({
    name: "",
    city: "",
    url: "",
    lat: "",
    long: "",
  });

  const [inspections, setInspections] = useState([]);
  const [links, setLinks] = useState([]);

  const [checkedValues, setCheckedValues] = useState([]);
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setCheckedValues((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };

  const [uris, setUris] = useState([]);
  const handleFileInput = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const ext = file.name.substr(file.name.lastIndexOf(".") + 1);

        // Skip large files > 5MB
        if (file.size > 5120000) {
          continue;
        }

        const reader = new FileReader();
        reader.onloadend = (e) => {
          setUris((prev) => [...prev, { uri: e.target.result, ext }]);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const [attachments, setAttachments] = useState([]);
  const deleteAttachmentFile = (index) => {
    const __attachments = [...attachments];
    __attachments.splice(index, 1);
    setAttachments([...__attachments]);
  };

  // Handles form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setUpdating(true);

    try {
      await getEditorData().then(async (desc) => {
        const images = await saveFiles(uris, COLLECTION_NAME);
        const files = await saveFiles(attachments, "files", true);
        const agentRefs = checkedValues.map((s) => {
          return doc(db, "teams", s);
        });
        const sold = property.pstatus.toUpperCase() === "SOLD";
        const leased = property.pstatus.toUpperCase() === "LEASED";
        const __property = {
          ...property,
          desc,
          loc,
          agents: agentRefs,
          inspections: [...inspections],
          images,
          files,
          links,
          sold,
          leased,
        };
        const propertiesRef = collection(db, COLLECTION_NAME);
        await addDoc(propertiesRef, {
          ...__property,
          createdAt: Timestamp.now(),
          updatedAt: Timestamp.now(),
        })
          .then(async (res) => {
            await updateDoc(doc(db, COLLECTION_NAME, res.id), {
              id: res.id,
            });
            await updateCount(COLLECTION_NAME, true);
            setUpdating(false);
            window.location.href = "/admin-dashboard/all-properties";
          })
          .catch((error) => {
            throw new Error(error);
          });
      });
    } catch (error) {
      console.error(error);
      setUpdating(false);
    }
  };

  useEffect(() => {
    if (typeSelected !== 2) {
      setProperty((d) => ({ ...d, area: "" }));
    } else if (typeSelected === 2) {
      setProperty((d) => ({
        ...d,
        beds: 0,
        tubs: 0,
        parkings: 0,
        pstatus: null,
        ptype: null,
      }));
    }

    setProperty((d) => ({
      ...d,
      rental: typeSelected === 1,
      sale: typeSelected === 0,
      land: typeSelected === 2,
    }));
  }, [typeSelected]);

  useEffect(() => {
    if (params.has("type")) {
      const type = params.get("type");
      setTypeSelected(
        type === "sale" ? 0 : type === "rent" ? 1 : type === "land" ? 2 : 1
      );
    }
  }, [params]);

  const editorRef = useRef(null);
  const loadScript = (url, callback) => {
    const existingScript = document.getElementById("ckeditor-script");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = url;
      script.id = "ckeditor-script";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (existingScript && callback) {
      callback();
    }
  };

  // Get all agents
  const getAgents = async () => {
    const ref = collection(db, "teams");
    await getDocs(ref).then((res) => {
      if (res.empty) return;
      setAgents(res.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    });
  };

  useEffect(() => {
    setTitle("Add property");
    getAgents();
    if (window.CKEDITOR) {
      initCKEditor();
    } else {
      loadScript("/lib/ckeditor/ckeditor.js", initCKEditor);
    }

    return () => {
      if (window.CKEDITOR) {
        for (let instance in window.CKEDITOR.instances) {
          window.CKEDITOR.instances[instance].destroy(true);
        }
        const script = document.getElementById("ckeditor-script");
        if (script) {
          document.body.removeChild(script);
        }
      }
    };
  }, []);

  const initCKEditor = () => {
    if (editorRef.current && window.CKEDITOR) {
      window.CKEDITOR.replace(editorRef.current);
    }
  };

  const getEditorData = () => {
    return new Promise((resolve) => {
      const content =
        window.CKEDITOR.instances[
          editorRef.current.getAttribute("id")
        ].getData();
      resolve(content);
    });
  };

  return (
    <div className="py-4 sm:py-10 px-6 sm:px-10 md:px-14 lg:px-20 space-y-10 duration-300">
      {/* Top App Bar */}
      <div className="flex items-center justify-between gap-10">
        <div className="flex flex-col items-start">
          <h1 className="tracking-tighter font-bold text-base tn:text-lg sm:text-xl duration-300">
            Add Property
          </h1>

          <div className="text-sm tracking-tight text-gray-600">
            Select other options for different types
          </div>
        </div>

        {/* Property type selector */}
        <TypeSelector selected={typeSelected} setParams={setParams} />
      </div>

      {/* Add property Form */}
      <form
        action={"#"}
        method="POST"
        onSubmit={handleSubmit}
        className="grid gap-6 tracking-tighter divide-y *:pt-6"
      >
        {/* Property Title */}
        <Title setProperty={setProperty} />

        {/* Property Subtitle */}
        <SubTitle setProperty={setProperty} />

        {/* Location */}
        <Location loc={loc} setLoc={setLoc} />

        {/* Area of property */}
        <Area setProperty={setProperty} />

        {/* Land */}
        {typeSelected === 2 ? null : (
          <Fragment>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
              className="w-full grid grid-cols-1 xsm:grid-cols-2 sm:grid-cols-3 gap-8"
            >
              {/* Beds */}
              <Beds setProperty={setProperty} />

              {/* Baths */}
              <Baths setProperty={setProperty} />

              {/* Parkings */}
              <Parkings setProperty={setProperty} />
            </motion.div>
          </Fragment>
        )}

        <div className="w-full grid grid-cols-1 lxs:grid-cols-2 gap-8 duration-300">
          {/* Property Type */}
          {typeSelected === 2 ? null : (
            <PropertyType setProperty={setProperty} />
          )}

          {/* Property Status */}
          <PropertyStatus
            options={
              typeSelected === 1
                ? propertyStatus.rent
                : typeSelected === 0
                ? propertyStatus.sale
                : propertyStatus.land
            }
            setProperty={setProperty}
          />
        </div>

        {/* For rental only */}
        {typeSelected === 1 ? (
          <div className="w-full grid grid-cols-1 lxs:grid-cols-2 gap-8 duration-300">
            {/* Rental per week */}
            <RentalPerWeek setProperty={setProperty} />

            {/* Security bond */}
            <SecurityBond setProperty={setProperty} />
          </div>
        ) : null}

        {/* Price of property */}
        <Price type={typeSelected} setProperty={setProperty} />

        {/* Status */}
        {/* <Status setProperty={setProperty} /> */}

        {/* Property Images */}
        <Images
          images={uris}
          setImages={setUris}
          handleFileInput={handleFileInput}
          deleteImage={(i) => {
            uris.splice(i, 1);
            setUris([...uris]);
          }}
        />

        {/* Property Description */}
        <Description ref={editorRef} />

        {/* Inspection times */}
        <InspectionTime
          inspections={inspections}
          setInspections={setInspections}
        />

        {/* Attachment files */}
        <div className="flex flex-col gap-4">
          <div className="flex items-start justify-between gap-4">
            <div className="flex flex-col items-start">
              <h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
                Attachments
              </h1>

              <span className="text-sm text-gray-800">
                Upload files (.docx, .pdf)
              </span>
            </div>

            {/* Upload files button */}
            <label
              htmlFor="attachments"
              className="rounded-sm flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-tr text-xs text-white cursor-pointer hover:rounded-lg duration-300"
            >
              <span>Upload files</span>
            </label>
          </div>

          {/* Files */}
          <Attachments setAttachments={setAttachments} />

          {attachments.length > 0 && (
            <div className="max-h-[320px] overflow-auto grid divide-y border rounded-sm odd:*:bg-gray-50 hover:*:bg-gray-100 *:cursor-default *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
              {attachments.map((file, index) => (
                <div className="group/file" key={index}>
                  <div className="w-full flex items-start gap-2">
                    <span className="relative top-1 text-xs text-gray-700">
                      {index + 1}.
                    </span>

                    <div className="w-full flex flex-col items-start tracking-tighter">
                      <div className="max-w-[70%] text-sm font-medium line-clamp-1 text-ellipsis group-hover/file:text-orange-500 duration-200">
                        {file?.name ?? ""}
                      </div>
                      <span className="text-xs text-gray-700">
                        {file?.size ?? ""}
                      </span>
                    </div>
                  </div>

                  <div
                    onClick={() => deleteAttachmentFile(index)}
                    className="text-lg hover:text-red-500 duration-100 invisible group-hover/file:visible opacity-0 group-hover/file:opacity-100 !cursor-pointer hover:scale-110"
                  >
                    <FiX />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Links */}
        <Links links={links} setLinks={setLinks} />

        {/* Agent */}
        <div className="space-y-10">
          <h1 className="inline-block tracking-tighter font-bold text-sm tn:text-base sm:text-lg duration-300">
            Contact
          </h1>

          <div className="w-full grid gap-2 justify-items-start">
            <motion.label
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              htmlFor="agent"
              className="font-medium text-sm"
            >
              <span>Choose Agents</span>
            </motion.label>

            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3, ease: "easeInOut" }}
              className="w-full h-auto"
            >
              <div className="w-full h-auto max-h-40 rounded-sm border shadow-sm grid divide-y">
                {agents.map((agent, i) => (
                  <label
                    key={agent.id + i}
                    htmlFor={"agent" + i}
                    className="flex items-center gap-4 h-11 px-4 cursor-pointer hover:bg-gray-50 duration-300"
                  >
                    <input
                      type="checkbox"
                      id={"agent" + i}
                      value={agent.id}
                      required={checkedValues.length <= 0}
                      onChange={handleCheckboxChange}
                      defaultChecked={checkedValues.includes(agent.id)}
                    />

                    <label
                      htmlFor={"agent" + i}
                      className="text-sm font-medium"
                    >
                      {(agent.firstname ?? "") +
                        " " +
                        (agent.middlename ?? "") +
                        " " +
                        (agent.lastname ?? "")}
                    </label>
                  </label>
                ))}
              </div>
            </motion.div>
          </div>
        </div>

        {/* Reset / Submit */}
        <div className="place-self-end flex items-center gap-6 flex-wrap">
          <button
            type="reset"
            className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-gray-300 to-gray-400 hover:bg-gradient-to-tr text-xs text-gray-700 font-semibold hover:text-black duration-300"
          >
            <span>Reset</span>
            <Icon icon="solar:close-square-outline" fontSize={16} />
          </button>

          <button
            type="submit"
            className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-10 h-10 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
          >
            <span>Add Property</span>
            <Icon icon="solar:add-square-bold" fontSize={16} />
          </button>
        </div>
      </form>
    </div>
  );
}
