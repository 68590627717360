import { compare, genSalt, hash } from "bcryptjs";

export async function setLocalStorage({ key, data }) {
	return new Promise((resolve, reject) => {
		if (typeof key !== "string") {
			reject(new Error('The "key" parameter must be a string.'));
			return;
		}

		try {
			localStorage.setItem(key, JSON.stringify(data));
			resolve({ success: true, key, data });
		} catch (error) {
			if (error instanceof DOMException && error.name === "QuotaExceededError") {
				reject(new Error("LocalStorage quota exceeded."));
			} else {
				reject(error);
			}
		}
	});
}

export async function getLocalStorage(key) {
	return new Promise((resolve, reject) => {
		if (typeof key !== "string") {
			reject(new Error('The "key" parameter must be a string.'));
			return;
		}

		try {
			const data = localStorage.getItem(key);
			if (data === null) {
				resolve(null); // Key doesn't exist in localStorage
			} else {
				resolve(JSON.parse(data));
			}
		} catch (error) {
			reject(error);
		}
	});
}

export async function setCookie(key = "", value = "", days = 1) {
	return new Promise((resolve, reject) => {
		try {
			let expires = "";
			if (days) {
				const date = new Date();
				date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
				expires = "; expires=" + date.toUTCString();
			}
			document.cookie = key + "=" + (value || "") + expires + "; path=/";
			resolve(true);
		} catch (error) {
			reject(error);
		}
	});
}

export async function getCookie(key = "") {
	return new Promise((resolve) => {
		const nameEQ = key + "=";
		const ca = document.cookie.split(";");
		for (let i = 0; i < ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) === " ") c = c.substring(1, c.length);
			if (c.indexOf(nameEQ) === 0) resolve(c.substring(nameEQ.length, c.length));
		}
		resolve(null);
	});
}

export function hashString(text) {
	return new Promise((resolve, reject) => {
		const saltRounds = 10;
		genSalt(saltRounds, (err, salt) => {
			if (err) {
				reject(err);
			} else {
				hash(text, salt, (err, hash) => {
					if (err) {
						reject(err);
					} else {
						resolve(hash);
					}
				});
			}
		});
	});
}

export function verifyString(input, hash) {
	return new Promise((resolve, reject) => {
		compare(input, hash, (err, result) => {
			if (err) {
				reject(err);
			} else {
				resolve(result);
			}
		});
	});
}

export function generateRandomToken(length) {
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
	let token = "";
	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		token += characters[randomIndex];
	}
	return token;
}
