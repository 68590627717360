import { FiX } from "react-icons/fi";
import { useDashboardContext } from "../../../dashboardContext";
import { COLLECTION_NAME } from "..";
import db from "../../../../firebase-config";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";
import { deleteFiles, saveFiles } from "../../utils";

export default function Files({ id = null, files = [], addedFromXML = false, closeModal }) {
	const { setUpdating } = useDashboardContext();
	const docRef = doc(db, COLLECTION_NAME, id);

	const [newFiles, setNewFiles] = useState([]);
	const [attachments, setAttachments] = useState([...files]);

	const deleteOldAttachmentFile = async (path, index) => {
		const confirmed = window.confirm("Are you sure to delete?");
		if (confirmed) {
			setUpdating(true);

			try {
				const deleted = (await deleteFiles([{ path }]))[0];
				if (deleted) {
					const __files = [...attachments];
					__files.splice(index, 1);
					await updateDoc(docRef, {
						files: [...__files],
					});
					setAttachments([...__files]);
					setUpdating(false);
				} else {
					throw new Error("Failed to delete file.");
				}
			} catch (error) {
				setUpdating(false);
				console.error(error);
			}
		} else {
			return;
		}
	};

	const handleFileInput = (event) => {
		const files = event.target.files;

		// Check if any of the files are selected or not 🙂
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				// Skip more than 10 files.
				if (i > 9) {
					break;
				}

				const file = files[i];

				const { name, size } = file;
				const ext = name.substr(file.name.lastIndexOf(".") + 1);

				let formattedSize = "";
				if (size < 1000) {
					formattedSize += size + " bytes";
				} else if (size < 1000000) {
					formattedSize += (size / 1024).toFixed(2) + " kb";
				} else {
					formattedSize += (size / 1024000).toFixed(2) + " mb";
				}

				// Skip the files which are larger than 5MB
				if (size > 5000000) {
					continue;
				}

				const reader = new FileReader();
				reader.onloadend = (e) => {
					setNewFiles((prev) => [...prev, { uri: e.target.result, name, size: formattedSize, ext }]);
				};

				reader.readAsDataURL(file);
			}
		}
	};

	const updateFiles = async () => {
		setUpdating(true);

		try {
			let __newFiles = [];
			if (newFiles.length > 0) {
				__newFiles = await saveFiles(newFiles, "files", true);
			}

			const combinedFiles = [...attachments, ...__newFiles];
			await updateDoc(docRef, {
				files: [...combinedFiles],
			})
				.then(() => {
					setAttachments([...combinedFiles]);
					setNewFiles([]);
					setUpdating(false);
				})
				.catch((error) => {
					throw new Error(error);
				});
		} catch (error) {
			setNewFiles([]);
			console.error(error);
			setUpdating(false);
		}
	};

	return (
		<div className="relative z-20 mx-auto flex flex-col rounded border bg-white overflow-auto w-full sm:w-10/12 md:w-8/12 lg:w-7/12 tracking-tighter *:w-full">
			<div className="flex items-center justify-between gap-4 px-4 py-4 border-b">
				<h1 className="text-lg font-semibold inline-flex gap-2 items-center">
					<span className="font-bold text-blue-600">{files.length}</span> Attachment Files
				</h1>

				<div
					role="button"
					onClick={closeModal}
					className="w-6 h-6 min-w-[24px] min-h-[24px] flex items-center justify-center rounded-full border bg-red-500 text-white text-base hover:scale-110 cursor-pointer duration-300"
				>
					<FiX />
				</div>
			</div>

			<div className="max-h-[320px] overflow-auto grid divide-y hover:*:bg-gray-100 *:px-4 *:py-2 *:flex *:items-center *:justify-between *:gap-4 *:w-full *:duration-300">
				{attachments.map((file, index) => (
					<div className="group/file" key={index}>
						<div className="w-full flex items-start gap-2">
							<span className="relative top-1 text-xs text-gray-700">{index + 1}.</span>

							<div className="w-full flex flex-col items-start tracking-tighter">
								<div className="max-w-[70%] text-sm font-medium line-clamp-1 text-ellipsis group-hover/file:text-orange-500 duration-200">
									<a href={file?.url ?? ""} target="_blank" rel="noreferrer">
										{file?.name ?? ""}
									</a>
								</div>
								<span className="text-xs text-gray-700">{file?.size ?? ""}</span>
							</div>
						</div>

						{addedFromXML ? null : (
							<div
								onClick={() => deleteOldAttachmentFile(file?.path, index)}
								className="text-lg hover:text-red-500 duration-100 invisible group-hover/file:visible opacity-0 group-hover/file:opacity-100 !cursor-pointer hover:scale-110"
							>
								<FiX />
							</div>
						)}
					</div>
				))}
			</div>

			{addedFromXML ? null : (
				<div className="w-full flex items-center justify-between gap-4 px-4 py-2 border-t bg-gray-50">
					{/* Multiple file uploads */}
					<input
						type="file"
						id="uploadFiles"
						multiple
						accept=".pdf, .docx"
						onChange={handleFileInput}
						className="hidden"
					/>

					<div className="flex items-center gap-4">
						<label
							htmlFor="uploadFiles"
							role="button"
							className="rounded-sm flex items-center justify-center gap-2 px-6 h-9 bg-gradient-to-br from-blue-400 to-blue-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Add files</span>
						</label>

						{newFiles.length > 0 && (
							<span className="text-sm font-medium">{newFiles.length} files selected.</span>
						)}
					</div>

					{newFiles.length > 0 && (
						<button
							type="button"
							onClick={updateFiles}
							className="rounded-sm outline-none border-none ring-0 flex items-center justify-center gap-2 px-6 h-9 bg-gradient-to-br from-orange-400 to-orange-600 hover:bg-gradient-to-tr text-xs text-white duration-300"
						>
							<span>Update</span>
						</button>
					)}
				</div>
			)}
		</div>
	);
}
