import React, { Fragment } from "react";

export default function Attachments({ setAttachments }) {
	const handleFileInput = (event) => {
		const files = event.target.files;

		// Check if any of the files are selected or not 🙂
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				const file = files[i];

				const { name, size } = file;
				const ext = name.substr(file.name.lastIndexOf(".") + 1);

				let formattedSize = "";
				if (size < 1000) {
					formattedSize += size + " bytes";
				} else if (size < 1000000) {
					formattedSize += (size / 1024).toFixed(2) + " kb";
				} else {
					formattedSize += (size / 1024000).toFixed(2) + " mb";
				}

				// Skip the files which are larger than 5MB
				if (size > 5000000) {
					continue;
				}

				const reader = new FileReader();
				reader.onloadend = (e) => {
					setAttachments((prev) => [...prev, { uri: e.target.result, name, size: formattedSize, ext }]);
				};

				reader.readAsDataURL(file);
			}
		}
	};

	return (
		<Fragment>
			<input
				type="file"
				id="attachments"
				multiple
				accept=".pdf, .docx"
				className="hidden"
				onChange={handleFileInput}
			/>
		</Fragment>
	);
}
