import { createBrowserRouter } from "react-router-dom";
import Layout from "./layout";
import { Page404 } from "./status";
import { ContainerContextProvider } from "./contexts/container";
import AdminRoutes from "./admin/routes";
import {
	HomePage,
	SearchLayout,
	// PropertiesLayout,
	// Properties,
	PropertyDetails,
	GetFreeAppraisal,
	RentalAppraisal,
	SellProperty,
	LatestNews,
	PropertiesForSale,
	PropertiesForRent,
	SoldPropperties,
	MeetOurTeam,
	ViewTeam,
	ViewNewsDetails,
	ContactUs,
	Testimonials,
	LandForSale,
	PrivacyPolicy,
	TermsAndConditions,
	LeasedProperties,
} from "./pages";

const router = createBrowserRouter([
	{
		path: "/",
		element: (
			<ContainerContextProvider>
				<Layout />
			</ContainerContextProvider>
		),
		children: [
			{
				index: true,
				element: <HomePage />,
			},
			{
				path: "sell-property",
				element: <SellProperty />,
			},
			{
				path: "get-a-free-appraisal",
				element: <GetFreeAppraisal />,
			},
			{
				path: "rental-appraisal",
				element: <RentalAppraisal />,
			},
			{
				path: "search",
				element: <SearchLayout />,
			},
			{
				path: "properties-for-sale",
				element: <PropertiesForSale />,
			},
			{
				path: "properties-for-sale/:id",
				element: <PropertyDetails />,
			},
			{
				path: "leased-properties",
				element: <LeasedProperties />,
			},
			{
				path: "leased-properties/:id",
				element: <PropertyDetails />,
			},
			{
				path: "properties-for-rent",
				element: <PropertiesForRent />,
			},
			{
				path: "properties-for-rent/:id",
				element: <PropertyDetails />,
			},
			{
				path: "land-for-sale",
				element: <LandForSale />,
			},
			{
				path: "land-for-sale/:id",
				element: <PropertyDetails />,
			},
			{
				path: "latest-news",
				element: <LatestNews />,
			},
			{
				path: "latest-news/:id",
				element: <ViewNewsDetails />,
			},
			{
				path: "sold-properties",
				element: <SoldPropperties />,
			},
			{
				path: "our-team",
				element: <MeetOurTeam />,
			},
			{
				path: "our-team/:id",
				element: <ViewTeam />,
			},
			{
				path: "contact-us",
				element: <ContactUs />,
			},
			{
				path: "testimonials",
				element: <Testimonials />,
			},
			{
				path: "privacy-policy",
				element: <PrivacyPolicy />,
			},
			{
				path: "terms-and-conditions",
				element: <TermsAndConditions />,
			},
		],
	},
	{
		path: "admin-dashboard/*",
		element: <AdminRoutes />,
	},
	{
		path: "*",
		element: <Page404 />,
	},
]);

export default router;
